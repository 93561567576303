import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { useAuth } from "src/containers/contexts/auth-provider";
import { Box } from "@mui/material";
import Loader from "../atoms/loader/loader";
import { authRoutes } from "src/features/tenant/constants/auth-routes";

interface Props {
  children: ReactNode;
}

export const AuthGuard = ({ children }: Props) => {
  const auth = useAuth();

  if (auth.loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (!auth.session) {
    const returnUrl = encodeURIComponent(window.location.pathname);
    // When reset password links is expired and user has no session we redirect to forgot-password page

    const isError = window.location.href.includes("error_code=401");

    if (!authRoutes.includes(window.location.pathname) && isError) {
      //we also check if there is any error in the url

      return <Navigate to={`/forgot-password?returnUrl=${returnUrl}&expired=${isError}`} />;
    }

    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace />;
  }

  return children;
};
