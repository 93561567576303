import { AvailableTenants } from 'src/types/responses/available-tenants';
import tenantApi from '../clients/tenant-api';

export const fetchAvailableTenants = async (): Promise<AvailableTenants> => {
  const { data } = await tenantApi.get<AvailableTenants>('/available', {
    //? Skip the interceptor adding tenant token to URL
    skipTenantAttachment: true,
  });
  return data;
};

export const confirmTenantJoin = async () => {
  await tenantApi.post('/confirm-join');
};
