import { FC } from "react";
import { Avatar, useTheme } from "@mui/material";
import { lightPrimary } from "src/colors";

interface Props {
  avatarText: string;
}

export const AccountPopoverAvatar: FC<Props> = ({ avatarText }) => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Avatar
      sx={{
        width: "40px",
        height: "40px",
        backgroundColor: mode === "light" ? "#E4F2F7" : "#19252C",
        border: `1px solid ${
          mode === "light" ? lightPrimary.borders : "#548697"
        }`,
        fontSize: "14px",
        fontWeight: "400",
        color: "text.primary",
        borderRadius: "100px",
      }}
      variant="rounded"
    >
      {avatarText}
    </Avatar>
  );
};
