export const MessagesIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3333 5.66635V8.99968M7.54162 3.58302H4.66663C3.26649 3.58302 2.56643 3.58302 2.03165 3.8555C1.56124 4.09518 1.17879 4.47763 0.939108 4.94804C0.666625 5.48282 0.666625 6.18289 0.666626 7.58302L0.666627 8.58302C0.666627 9.35959 0.666627 9.74787 0.793495 10.0542C0.962652 10.4625 1.28711 10.787 1.69549 10.9561C2.00177 11.083 2.39006 11.083 3.16663 11.083V14.6247C3.16663 14.8182 3.16663 14.9149 3.17465 14.9964C3.25259 15.7877 3.87864 16.4137 4.66993 16.4917C4.75142 16.4997 4.84815 16.4997 5.04163 16.4997C5.2351 16.4997 5.33184 16.4997 5.41332 16.4917C6.20462 16.4137 6.83067 15.7877 6.9086 14.9964C6.91663 14.9149 6.91663 14.8182 6.91663 14.6247V11.083H7.54163C9.01365 11.083 10.8143 11.8721 12.2035 12.6294C13.014 13.0712 13.4192 13.2921 13.6846 13.2596C13.9307 13.2294 14.1168 13.1189 14.2611 12.9173C14.4166 12.6998 14.4166 12.2647 14.4166 11.3944V3.27159C14.4166 2.40134 14.4166 1.96622 14.2611 1.74876C14.1168 1.5471 13.9307 1.4366 13.6846 1.40646C13.4192 1.37395 13.014 1.59485 12.2035 2.03664C10.8143 2.79394 9.01365 3.58302 7.54162 3.58302Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
