import { lightSuccess, lightWarning } from 'src/colors';
import { AdminInvitationStatus } from '../types/enum/admin-invitation-status';

export const adminInvitationStateTranslations = {
  [AdminInvitationStatus.ACCEPTED]:
    'siteManagement:administrators.invitationStatus.accepted' as const,
  [AdminInvitationStatus.PENDING]:
    'siteManagement:administrators.invitationStatus.pending' as const,
};

export const adminInvitationStateColor = {
  [AdminInvitationStatus.PENDING]: lightWarning.light,
  [AdminInvitationStatus.ACCEPTED]: lightSuccess.dark,
};
