import { lightPrimary, lightText, lightWarning } from 'src/colors';
import { UserRole, UserRoleTranslations } from 'src/types/enum/user-role';

export const adminsRoleTranslations = {
  [UserRole.MASTER]: UserRoleTranslations.MASTER,
  [UserRole.ADMIN]: UserRoleTranslations.ADMIN,
  [UserRole.VIEWER]: UserRoleTranslations.VIEWER,
  [UserRole.OWNER]: UserRoleTranslations.OWNER,
};

export const adminsRolesColor = {
  [UserRole.MASTER]: lightPrimary.hoverOutlined,
  [UserRole.OWNER]: lightPrimary.hoverOutlined,
  [UserRole.ADMIN]: lightWarning.light,
  [UserRole.VIEWER]: lightText.secondary,
};
