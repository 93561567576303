export const PollsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.583374"
        y="0.583008"
        width="14.8333"
        height="14.8333"
        rx="2"
        stroke="currentColor"
      />
      <rect
        x="4.25"
        y="7.58301"
        width="1.66667"
        height="5"
        rx="0.25"
        fill="currentColor"
      />
      <rect
        x="7.58337"
        y="5.08301"
        width="1.66667"
        height="7.5"
        rx="0.25"
        fill="currentColor"
      />
      <rect
        x="10.9166"
        y="9.24951"
        width="1.66667"
        height="3.33333"
        rx="0.25"
        fill="currentColor"
      />
    </svg>
  );
};
