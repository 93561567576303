import { Box, Card, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  logo: string;
  name: string;
  description: string;
  disabled: boolean;
  isAvailable: boolean;
  href: string;
}

export default function MarketplaceApplication({
  logo,
  name,
  description,
  disabled,
  isAvailable,
  href,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}>
      <Link
        to={href}
        target="_blank"
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          textDecoration: 'none',
        }}>
        <Card
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            textAlign: 'center',
            gap: 1,
            p: 2,
            height: '100%',
            boxShadow: 'none',
            ':hover': {
              boxShadow: disabled ? 'none' : '0px 2px 4px 0px #00000026',
              backgroundColor:
                !disabled && mode === 'dark' ? 'rgba(34, 35, 39, 1)' : '',
            },
            transition: 'all 0.2s',
          }}>
          <Box
            sx={{
              width: '70px',
              height: '70px',
            }}>
            <img
              src={logo}
              alt={name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: '10px' }}>
            {description}
          </Typography>
          {!isAvailable ? (
            <Box
              sx={{
                position: 'absolute',
                top: '10%',
                right: '15%',
                backgroundColor:
                  mode === 'light' ? 'rgba(0, 0, 0, 1)' : 'rgba(80, 78, 78, 1)',
                border:
                  mode === 'dark' ? '0.7px solid rgba(35, 35, 37, 1)' : 'none',
                px: 1,
                py: 0.1,
                borderRadius: 100,
                lineHeight: '17px',
              }}>
              <Typography color="#FFF" sx={{ fontSize: '8px' }}>
                {t('marketplace:applications.comingSoon')}
              </Typography>
            </Box>
          ) : null}
        </Card>
      </Link>
    </Box>
  );
}
