import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { siteAdministratorsKeys } from '../../api/query-keys/administrators';
import { ApiError } from 'src/types/interfaces/api-error';
import { delayedCallbackExecution } from 'src/utils/delayed-callback-execution';
import { resendAdminInvitation } from '../../api/lib/admins';

export const useResendAdminInvitation = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (siteAdminToken: string) =>
      resendAdminInvitation(siteAdminToken),
    onSuccess: () => {
      delayedCallbackExecution(1000, () => {
        queryClient.invalidateQueries({
          queryKey: siteAdministratorsKeys.lists(),
        });
      });

      onSuccess?.();

      toast.success(
        t('siteManagement:administrators.resendAdminInvitationDialog.success'),
      );
    },
    onError: (error: ApiError) => {
      toast.error(error?.response?.data?.message);
    },
  });
};
