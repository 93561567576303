import { useUserStore } from 'src/store/user-store';
import { useEffect } from 'react';
import { useAvailableSites } from './api/use-available-sites';
import { SiteAdminInvitationStatus } from 'src/types/enum/site-admin-invitation-status.enum';
import { useConfirmSiteJoin } from './api/use-confirm-site-join';

export const useConfirmJoinCurrentlySelectedSite = (enabled: boolean) => {
  const directoryToken = useUserStore().directoryToken;

  const { data, isLoading } = useAvailableSites(enabled);

  const selectedSite = data?.resources.find(
    site => site.token === directoryToken,
  );

  const { mutate } = useConfirmSiteJoin();

  useEffect(() => {
    if (
      selectedSite &&
      !isLoading &&
      directoryToken &&
      selectedSite.status === SiteAdminInvitationStatus.PENDING
    ) {
      mutate();
    }
  }, [selectedSite, isLoading, directoryToken, mutate]);
};
