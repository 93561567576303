export const MobileAppManagementIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V14C0.5 14.8284 1.17157 15.5 2 15.5H5C5.82843 15.5 6.5 14.8284 6.5 14V2C6.5 1.17157 5.82843 0.5 5 0.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 0.5H11C10.1716 0.5 9.5 1.17157 9.5 2V5C9.5 5.82843 10.1716 6.5 11 6.5H14C14.8284 6.5 15.5 5.82843 15.5 5V2C15.5 1.17157 14.8284 0.5 14 0.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.5H11C10.1716 9.5 9.5 10.1716 9.5 11V14C9.5 14.8284 10.1716 15.5 11 15.5H14C14.8284 15.5 15.5 14.8284 15.5 14V11C15.5 10.1716 14.8284 9.5 14 9.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
