import { ComponentType, Suspense, lazy } from 'react';
import type { DataRouteObject } from 'react-router';
import { Layout } from 'src/layout/main-layout';
import { LoadingScreen } from './components/loading-screen';
import { AuthGuard } from './components/guards/auth-guard';

const Loadable =
  <T extends object>(Component: ComponentType<T>): ComponentType<T> =>
  props => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(
  lazy(() =>
    import('./containers/auth/login').then(module => ({
      default: module.LoginContainer,
    })),
  ),
);

const ForgotPassword = Loadable(
  lazy(() =>
    import('./containers/auth/forgot-password').then(module => ({
      default: module.ForgotPasswordContainer,
    })),
  ),
);

const UpdatePassword = Loadable(
  lazy(() =>
    import('./containers/auth/update-password').then(module => ({
      default: module.UpdatePasswordContainer,
    })),
  ),
);

const SetUpPassword = Loadable(
  lazy(() =>
    import('./containers/auth/set-up-password').then(module => ({
      default: module.SetUpPasswordContainer,
    })),
  ),
);

const ManageInvitationDialogue = Loadable(
  lazy(() =>
    import(
      './features/tenant-manage-invitation-dialogue/containers/manage-invitation-dialogue'
    ).then(module => ({
      default: module.ManageInvitationDialogueContainer,
    })),
  ),
);

const CreateSiteContainer = Loadable(
  lazy(() =>
    import('./features/create-site/containers/create-site').then(module => ({
      default: module.default,
    })),
  ),
);

// Not found page
const NotFound = Loadable(
  lazy(() =>
    import('./containers/not-found').then(module => ({
      default: module.NotFound,
    })),
  ),
);

const ChangePasswordContainer = Loadable(
  lazy(() =>
    import('./features/password/containers/change-password').then(module => ({
      default: module.default,
    })),
  ),
);

const routes: DataRouteObject[] = [
  {
    id: 'login-container',
    path: 'login',
    children: [
      {
        id: 'login',
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    id: 'forgot-password-container',
    path: 'forgot-password',
    children: [
      {
        id: 'forgot-password',
        index: true,
        element: <ForgotPassword />,
      },
    ],
  },
  {
    id: 'set-up-password-container',
    path: 'set-up-password',
    children: [
      {
        id: 'set-up-password',
        index: true,
        element: <SetUpPassword />,
      },
    ],
  },
  {
    id: 'reset-password-container',
    path: 'reset-password',
    children: [
      {
        id: 'reset-password',
        index: true,
        element: <UpdatePassword />,
      },
    ],
  },
  {
    id: 'tenant-invitation',
    path: 'tenant-invitation/:invitationToken',
    element: <ManageInvitationDialogue />,
  },
  {
    id: 'create-site',
    path: 'create-site',
    element: <CreateSiteContainer />,
  },
  {
    id: 'not-found',
    path: '*',
    element: <NotFound />,
  },
  {
    id: 'password-container',
    path: 'password',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'password',
        index: true,
        element: <ChangePasswordContainer />,
      },
    ],
  },
];

export default routes;
