import { useQuery } from '@tanstack/react-query';
import { ApiError } from 'src/types/interfaces/api-error';
import { AvailableSites } from 'src/types/responses/available-sites';
import { fetchAvailableSites } from 'src/api/lib/site';
import { availableSitesKeys } from 'src/api/query-keys/site/available-sites.query-keys';

export const useAvailableSites = (enabled?: boolean) => {
  return useQuery<AvailableSites, ApiError>({
    queryFn: fetchAvailableSites,
    queryKey: availableSitesKeys.all,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
