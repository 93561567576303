import components from './components.json';
import createPlace from './create-place.json';
import details from './details.json';
import editPlace from './edit-place.json';
import placeSteps from './place-steps.json';
import placeTypes from './place-types.json';
import places from './places.json';
import validation from './validation.json';

export default {
  places,
  details,
  createPlace,
  editPlace,
  placeSteps,
  placeTypes,
  validation,
  components,
};
