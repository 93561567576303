import { Tooltip } from '@mui/material';
import { formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Status } from 'src/components/atoms/status';
import { TenantInvitationStatus } from '../../types/enums/tenant-invitation-status';
import { dateFormats } from 'src/utils/date-formats';
import {
  invitationStateColor,
  invitationStateTranslations,
} from '../../constants/tenant-invitation-status';

interface Props {
  invitationStatus: TenantInvitationStatus;
  invitationDate: Date;
}

const TenantInvitationState = ({ invitationStatus, invitationDate }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t('siteTenants:tenants.invitationDate', {
        date: formatDate(
          new Date(invitationDate),
          `${dateFormats.day} ${dateFormats.monthShort}, ${dateFormats.year}`,
        ),
      })}
      disableHoverListener={!invitationDate}
      disableFocusListener={!invitationDate}>
      <span>
        <Status
          label={t(invitationStateTranslations[invitationStatus])}
          color={invitationStateColor[invitationStatus]}
        />
      </span>
    </Tooltip>
  );
};

export default TenantInvitationState;
