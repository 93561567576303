import meiliSearchApiClient from 'src/api/clients/meilisearch';
import siteApi from 'src/api/clients/site';
import { SearchState } from 'src/features/tenant/types/interfaces/search-state';
import { UserRole } from 'src/types/enum/user-role';
import { MeiliSearchResponse } from 'src/types/responses/meilisearch';
import { calculatePaginationOffset } from 'src/utils/calculate-pagination-offset';
import { Admin } from '../types/admin';

export const fetchAdmins = async (
  siteToken: string,
  searchState: SearchState,
  page: number,
  perPage: number,
): Promise<MeiliSearchResponse<Admin>> => {
  const { data } = await meiliSearchApiClient.post<MeiliSearchResponse<Admin>>(
    `/indexes/SiteAdmin-${siteToken}/search`,
    {
      ...searchState,
      limit: perPage,
      offset: calculatePaginationOffset(page, perPage),
    },
  );

  return data;
};

export const inviteAdmin = async (
  email: string,
  role: UserRole,
  phoneNumber: string | undefined,
): Promise<void> => {
  await siteApi.post(`/admins`, {
    email,
    role,
    phoneNumber,
  });
};

export const editAdminPhoneNumber = async (
  siteAdminToken: string,
  phoneNumber: string,
): Promise<void> => {
  await siteApi.put(`/admins/${siteAdminToken}/contact`, {
    phoneNumber,
  });
};

export const editAdminRole = async (
  siteAdminToken: string,
  role: UserRole,
): Promise<void> => {
  await siteApi.post(`/admins/${siteAdminToken}/role`, {
    role,
  });
};

export const resendAdminInvitation = async (
  siteAdminToken: string,
): Promise<void> => {
  await siteApi.post(`/admins/${siteAdminToken}/resend`);
};

export const removeAdmin = async (siteAdminToken: string): Promise<void> => {
  await siteApi.delete(`/admins/${siteAdminToken}`);
};
