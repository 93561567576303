export const ContactFormIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3206 15.8332H5.51286C4.31414 15.8332 3.33337 14.8786 3.33337 13.712V6.28772C3.33337 5.12105 4.31414 4.1665 5.51286 4.1665H15.3206C16.5193 4.1665 17.5 5.12105 17.5 6.28772V13.712C17.5 14.8786 16.5193 15.8332 15.3206 15.8332Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33337 7.5L10.4167 10.8333L17.5 7.5"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
