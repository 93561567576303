import { IconButton } from '@mui/material';
import { useDialog } from 'src/hooks/use-dialog';
import AppsIcon from '@mui/icons-material/Apps';
import MarketplaceDialog from './marketplace-dialog';

export default function MarketplaceButton() {
  const [
    isOpenMarketplaceDialog,
    handleOpenMarketplaceDialog,
    handleCloseMarketplaceDialog,
  ] = useDialog();

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleOpenMarketplaceDialog}
        sx={{
          mr: 1,
          display: 'inline-flex',
        }}>
        <AppsIcon color="primary" />
      </IconButton>
      <MarketplaceDialog
        open={isOpenMarketplaceDialog}
        onCancel={handleCloseMarketplaceDialog}
      />
    </>
  );
}
