import tab from './tab.json';
import filters from './filters.json';
import table from './table.json';
import details from './details.json';
import createFaq from './create-faq.json';
import faqSteps from './faq-steps.json';

export default {
  tab,
  filters,
  table,
  details,
  createFaq,
  faqSteps,
};
