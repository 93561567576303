import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'src/icons/chevron-down';
import { useSettingsStore } from 'src/store/settings-store';
import { SidebarRouteTitle } from 'src/types/enum/route-title';
import { Route } from 'src/types/interfaces/route';
import { SidebarItem } from './sidebar-item';

interface Props {
  title: SidebarRouteTitle;
  routes: Route[];
  activeRoute: Route | null;
  pinned: boolean;
}

export default function SidebarItems({
  title,
  routes,
  activeRoute,
  pinned,
}: Props) {
  const { t } = useTranslation();
  const {
    palette: { mode },
  } = useTheme();

  const openedSidebarGroups = useSettingsStore(
    state => state.openedSidebarGroups,
  );
  const saveSettings = useSettingsStore(state => state.saveSettings);

  const [openItemsList, setOpenItemsList] = useState<boolean>(
    openedSidebarGroups.includes(title),
  );

  // TODO: refactor this to make the save as effect of the change
  const toggleItemsList = () => {
    setOpenItemsList(previousState => !previousState);

    saveSettings({
      openedSidebarGroups: !openItemsList
        ? [...openedSidebarGroups, title]
        : openedSidebarGroups.filter(group => group !== title),
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          mb: 1,
          cursor: 'pointer',
          p: 1,
          borderRadius: 0.75,
          ':hover': {
            background: {
              md: mode === 'dark' ? '#ededed0a' : '#0000000a',
            },
          },
        }}
        onClick={toggleItemsList}>
        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
          <Typography variant="body2" color="text.primary" fontWeight="500">
            {t(title)}
          </Typography>
        </Box>
        <ChevronDown
          sx={{
            rotate: openItemsList ? '0deg' : '-90deg',
            color: openItemsList ? 'text.primary' : 'text.secondary',
          }}
          fontSize="small"
        />
      </Box>

      {routes.map(route => (
        <Collapse in={openItemsList} key={route.id}>
          <Box>
            <SidebarItem
              {...route}
              key={route.title}
              href={route.href as string}
              active={activeRoute?.title === route.title}
              external={route.external}
              title={route.title}
              pinned={pinned}
              id={route?.id}
            />
          </Box>
        </Collapse>
      ))}
    </Box>
  );
}
