import { useUserStore } from 'src/store/user-store';
import { useAvailableTenants } from './api/use-available-tenants';
import { useEffect } from 'react';
import { TenantInvitationStatus } from 'src/types/enum/tenant-invitation-status';
import { useConfirmTenantJoin } from './api/use-confirm-tenant-join';

export const useConfirmJoinCurrentlySelectedTenant = (enabled: boolean) => {
  const directoryToken = useUserStore().directoryToken;

  const { data, isLoading } = useAvailableTenants(enabled);

  const selectedTenant = data?.resources.find(
    tenant => tenant.token === directoryToken,
  );

  const { mutate } = useConfirmTenantJoin();

  useEffect(() => {
    if (
      selectedTenant &&
      !isLoading &&
      directoryToken &&
      selectedTenant.status === TenantInvitationStatus.PENDING
    ) {
      mutate();
    }
  }, [selectedTenant, isLoading, directoryToken, mutate]);
};
