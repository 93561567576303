import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'src/components/atoms/scrollbar';
import { DataStatusIndicator } from 'src/components/molecules/data-status-indicator/data-status-indicator';
import { Admin } from 'src/features/site-management/api/types/admin';
import { AdminsActionSlotPayload } from 'src/features/site-management/types/payloads/admins-action-slot-payload';
import { ReactNode } from 'react';
import AdminsTableRow from './admins-table-row';

interface Props {
  data: Admin[];
  isFetching: boolean;
  error: string;
  actionsSlot: (payload: AdminsActionSlotPayload) => ReactNode;
}

const AdminsTable = ({ data, error, isFetching, actionsSlot }: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      id: 'email',
      label: t('siteManagement:administrators.email'),
    },
    {
      id: 'phoneNumber',
      label: t('siteManagement:administrators.phoneNumber'),
    },
    {
      id: 'role',
      label: t('siteManagement:administrators.role'),
    },
    {
      id: 'invitationStatus',
      label: t('siteManagement:administrators.status'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      <Scrollbar autoHide={false}>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ email, role, phoneNumber, token, status }) => (
              <AdminsTableRow
                key={token}
                token={token}
                email={email}
                phoneNumber={phoneNumber}
                role={role}
                status={status}
                actionsSlot={actionsSlot}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <DataStatusIndicator
        dataLength={data?.length}
        error={error}
        isFetching={isFetching}
      />
    </Box>
  );
};

export default AdminsTable;
