import { Location } from 'react-router-dom';
import { MeiliSearchSearchState } from 'src/features/tenant/hooks/meilisearch/types/meilisearch-search-state';
import { decodeAndParseQueryParams } from './decode-and-parse-query-params';

export const urlToSearchState = (
  location: Location,
): MeiliSearchSearchState => {
  const urlSearch = location.search.slice(1);

  const parsedSearchState = decodeAndParseQueryParams(
    urlSearch,
  ) as unknown as MeiliSearchSearchState;

  return {
    ...parsedSearchState,
    q: parsedSearchState?.q?.toString() ?? '',
  };
};
