import { AvailableSites } from 'src/types/responses/available-sites';
import siteApi from '../clients/site';

export const fetchAvailableSites = async (): Promise<AvailableSites> => {
  const { data } = await siteApi.get<AvailableSites>('/available', {
    //? Skip the interceptor adding tenant token to URL
    skipTenantAttachment: true,
  });

  return data;
};

export const confirmSiteJoin = async () => {
  await siteApi.post('/confirm-join');
};

export const fetchSiteSuggestedHashtags = async (): Promise<string[]> => {
  const { data } = await siteApi.get<{ hashtags: string[] }>(
    '/hashtags/suggested',
  );

  return data.hashtags;
};
