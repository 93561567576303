export const FaqsIcon = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 12.2829V12.8285C4 13.3735 3.82488 14.4982 4.19995 14.8835C4.57502 15.2689 5.46952 15.5 5.99995 15.5C6.53038 15.5 7.42488 15.2689 7.79995 14.8835C8.17502 14.4982 8 13.3735 8 12.8285V12.2829M4 12.2829C4 11.3633 3.644 10.4807 3.012 9.83139L2.464 9.26937C1.76487 8.55086 1.2888 7.63549 1.09598 6.63899C0.90316 5.6425 1.00225 4.60964 1.38073 3.671C1.75921 2.73237 2.40007 1.93012 3.22229 1.36569C4.0445 0.801261 5.01115 0.5 6 0.5C6.98885 0.5 7.9555 0.801261 8.77771 1.36569C9.59993 1.93012 10.2408 2.73237 10.6193 3.671C10.9977 4.60964 11.0968 5.6425 10.904 6.63899C10.7112 7.63549 10.2351 8.55086 9.536 9.26937L8.988 9.83139C8.67471 10.1533 8.42621 10.5355 8.25668 10.9562C8.08716 11.3768 7.99994 11.8276 8 12.2829M4 12.2829H5.99995M8 12.2829H5.99995M5.99995 12.2829L6 6.40876H4H8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
