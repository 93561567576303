import { useEffect } from 'react';
import { useGenerateTenantSearchKey } from 'src/features/tenant/hooks/meilisearch/use-generate-search-key';
import { useUserStore } from 'src/store/user-store';
import { useGenerateSiteSearchKey } from './use-generate-site-search-key';

export const useMeilisearchApiKey = (enabled: boolean = true) => {
  const meilisearchToken = useUserStore().meilisearchToken;
  const setMeilisearchToken = useUserStore().setMeilisearchToken;
  const viewContext = useUserStore().viewContext;

  const { data: newMeilisearchTenantToken } = useGenerateTenantSearchKey(
    viewContext === 'tenant' && enabled,
  );

  const { data: newMeilisearchSiteToken } = useGenerateSiteSearchKey(
    viewContext === 'site' && enabled,
  );

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (
        newMeilisearchTenantToken &&
        newMeilisearchTenantToken !== meilisearchToken
      ) {
        setMeilisearchToken(newMeilisearchTenantToken);
      }
    } else {
      if (
        newMeilisearchSiteToken &&
        newMeilisearchSiteToken !== meilisearchToken
      ) {
        setMeilisearchToken(newMeilisearchSiteToken);
      }
    }
  }, [
    meilisearchToken,
    newMeilisearchSiteToken,
    newMeilisearchTenantToken,
    setMeilisearchToken,
    viewContext,
  ]);
};
