import { SearchState } from 'src/features/tenant/types/interfaces/search-state';

export const siteTenantsKeys = {
  all: ['site', 'tenants'] as const,
  lists: () => [...siteTenantsKeys.all, 'list'] as const,
  list: (
    siteToken: string,
    limit: number,
    offset: number,
    searchState?: SearchState,
  ) =>
    [
      ...siteTenantsKeys.lists(),
      siteToken,
      { limit, offset, ...searchState },
    ] as const,
  details: () => [...siteTenantsKeys.all, 'detail'] as const,
  detail: (token: string) => [...siteTenantsKeys.details(), token] as const,
};
