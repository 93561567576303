import { useTranslation } from 'react-i18next';
import {
  Action,
  ActionsTableMenu,
} from 'src/components/atoms/actions-table-menu';
import { usePermissions } from 'src/permissions/can';
import { TenantInvitationStatus } from '../../types/enums/tenant-invitation-status';

interface Props {
  onCancelInvitation: () => void;
  invitationStatus: TenantInvitationStatus;
}

const TenantsActionsTableMenu = ({
  onCancelInvitation,
  invitationStatus,
}: Props) => {
  const { t } = useTranslation();

  const permissions = usePermissions();

  const actions: Action[] = [
    {
      id: 'cancelInvitation',
      label: t('siteTenants:tenants.actions.cancelInvitation'),
      onClick: onCancelInvitation,
      disabled:
        permissions.cannot('delete', 'SiteTenants') ||
        invitationStatus === TenantInvitationStatus.ACCEPTED,
      tooltip:
        invitationStatus === TenantInvitationStatus.ACCEPTED
          ? t('siteTenants:tenants.alreadyAccepted')
          : t('common:notSufficientPermissions'),
    },
  ];

  return <ActionsTableMenu actions={actions} />;
};

export default TenantsActionsTableMenu;
