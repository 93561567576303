import { Box, Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';

interface Props {
  isFetching: boolean;
  error: string | undefined;
  dataLength: number;
  unavailableComponent?: ReactElement | null;
}

export const DataStatusIndicator = ({
  dataLength,
  error,
  isFetching,
  unavailableComponent = null,
}: Props) => {
  const displayLoading = isFetching;
  const displayError = Boolean(!isFetching && error);
  const displayUnavailable = Boolean(!isFetching && !error && !dataLength);

  return (
    <>
      {displayLoading && (
        <Box sx={{ p: 2 }} data-testid="skeletons-wrapper">
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      {displayUnavailable &&
        (unavailableComponent ? (
          unavailableComponent
        ) : (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2,
            }}
          />
        ))}
    </>
  );
};
