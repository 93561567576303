import type { FC } from "react";

import { Box, SxProps } from "@mui/material";

interface StatusBadgeProps {
  color: string;
  sx?: SxProps;
}

export const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const { color, sx, ...other } = props;

  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: "50%",
        height: 8,
        width: 8,
        ...sx,
      }}
      {...other}
    />
  );
};
