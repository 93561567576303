import { StorageKeys, StorageService } from 'src/services/storage.service';
import { UserRole } from 'src/types/enum/user-role';
import { create } from 'zustand';

export type ViewContext = 'site' | 'tenant';

//? directory = tenant | site
interface UserStore {
  isChangingDirectoryToken: boolean;
  setIsChangingDirectory: (isChanging: boolean) => void;

  directoryToken: string | null;
  setDirectoryToken: (token: string) => void;
  clearDirectoryToken: () => void;

  viewContext: ViewContext | null;
  setViewContext: (viewContext: ViewContext) => void;
  clearViewContext: () => void;

  adminToken: string | null;
  setAdminToken: (token: string) => void;
  clearAdminToken: () => void;

  role: UserRole | null;
  setRole: (role: UserRole) => void;
  clearRole: () => void;

  meilisearchToken: string | null;
  setMeilisearchToken: (token: string) => void;
  clearMeilisearchToken: () => void;

  isLoadingViewContextAccess: boolean;
  setIsLoadingViewContextAccess: (isLoading: boolean) => void;
}

export const useUserStore = create<UserStore>(set => ({
  isChangingDirectoryToken: false,
  setIsChangingDirectory: (isChanging: boolean) =>
    set(() => ({
      isChangingDirectoryToken: isChanging,
    })),

  directoryToken: StorageService.getItem(StorageKeys.DIRECTORY_TOKEN) ?? null,
  setDirectoryToken: directoryToken =>
    set(() => {
      StorageService.setItem(StorageKeys.DIRECTORY_TOKEN, directoryToken);

      return {
        directoryToken,
      };
    }),
  clearDirectoryToken: () =>
    set({
      directoryToken: null,
    }),

  meilisearchToken: null,
  setMeilisearchToken: (meilisearchToken: string) =>
    set(() => {
      StorageService.setItem(StorageKeys.MEILISEARCH_TOKEN, meilisearchToken);

      return {
        meilisearchToken,
      };
    }),
  clearMeilisearchToken: () =>
    set(() => {
      StorageService.removeItem(StorageKeys.MEILISEARCH_TOKEN);
      return { meilisearchToken: null };
    }),

  viewContext:
    (StorageService.getItem(StorageKeys.VIEW_CONTEXT) as ViewContext) ??
    'tenant',
  setViewContext: (viewContext: ViewContext) =>
    set(() => {
      StorageService.setItem(StorageKeys.VIEW_CONTEXT, viewContext);
      return { viewContext };
    }),

  clearViewContext: () =>
    set(() => {
      StorageService.removeItem(StorageKeys.VIEW_CONTEXT);
      return { viewContext: null };
    }),

  isLoadingViewContextAccess: true,
  setIsLoadingViewContextAccess: (isLoadingViewContextAccess: boolean) =>
    set(() => ({ isLoadingViewContextAccess })),

  adminToken: localStorage.getItem(StorageKeys.ADMIN_TOKEN) ?? null,
  setAdminToken: (adminToken: string) =>
    set(() => {
      StorageService.setItem(StorageKeys.ADMIN_TOKEN, adminToken);
      return {
        adminToken,
      };
    }),
  clearAdminToken: () =>
    set(() => {
      StorageService.removeItem(StorageKeys.ADMIN_TOKEN);
      return { adminToken: null };
    }),

  role: (localStorage.getItem(StorageKeys.ROLE) as UserRole) ?? null,
  setRole: (role: UserRole) =>
    set(() => {
      StorageService.setItem(StorageKeys.ROLE, role);
      return {
        role,
      };
    }),
  clearRole: () =>
    set(() => {
      StorageService.removeItem(StorageKeys.ROLE);
      return { role: null };
    }),
}));
