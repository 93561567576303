import type { FC } from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavbarMenu } from './navbar-menu';

import type { Theme } from '@mui/material';

import { ChevronDown } from 'src/icons/chevron-down';
import { Moon } from 'src/icons/moon';
import { Question } from 'src/icons/question';
import { Sun } from 'src/icons/sun';
import { useUserStore } from 'src/store/user-store';
import { LanguagePopover } from '../molecules/language-popover';
import { AccountPopover } from './account-popover';
import { PanelContextSelector } from './panel-context-selector';
import MarketplaceButton from '../../features/marketplace/components/organisms/marketplace-button';
import { useSettingsStore } from 'src/store/settings-store';

export const Navbar: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { i18n, t } = useTranslation();

  const settingsTheme = useSettingsStore(state => state.theme);
  const saveSettings = useSettingsStore(state => state.saveSettings);

  const [openMenu, setOpenMenu] = useState(false);

  const { viewContext } = useUserStore();
  const theme = useTheme();
  const { mode } = theme.palette;

  const infoApp = {
    icon: '/static/sos_logo.svg',
    name: 'Building Management',
  };

  const handleLanguageChange = (language: 'en' | 'pl'): void => {
    i18n.changeLanguage(language);
    saveSettings({
      language,
    });
    toast.success(t('common:languageChanged'));
  };

  const handleSwitchTheme = (): void => {
    saveSettings({
      theme: settingsTheme === 'light' ? 'dark' : 'light',
    });
  };

  const helpjuiceUrl = () => {
    switch (viewContext) {
      case 'site':
        switch (i18n.language) {
          case 'en':
            return 'https://signalosio.helpjuice.com/en_US/signal-on-site';
          case 'pl':
            return 'https://signalosio.helpjuice.com/pl_PL/signal-on-site-';
        }
        break;
      case 'tenant':
        switch (i18n.language) {
          case 'en':
            return 'https://signalosio.helpjuice.com/en_US/signal-on-site';
          case 'pl':
            return 'https://signalosio.helpjuice.com/pl_PL/signal-on-site-';
        }
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'primary.contrastText', zIndex: '999' }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,

          pr: 2,
          py: 1,
        }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: '72px',
            transition: '0.3s',
            position: 'relative',
            '&:hover .apps': {
              visibility: 'visible',
              opacity: 1,
            },
          }}>
          <Box
            className="logo"
            sx={{
              transition: '0.3s',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}>
            <img
              src={infoApp.icon}
              alt="logo"
              style={{ width: '42px', height: '42px' }}
            />
          </Box>
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mr: 3,
          }}
        />
        <Box sx={{ color: 'text.primary' }}>{infoApp.name}</Box>
        <NavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          endIcon={
            <ChevronDown
              fontSize="small"
              sx={{
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none',
              }}
            />
          }
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.text',
            display: {
              md: 'none',
              xs: 'flex',
            },
            fontSize: '14px',
            ml: 2,
          }}
          variant="text">
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />

        <PanelContextSelector />

        <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          sx={{
            mx: 1,
            display: 'inline-flex',
          }}
        />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 1,
            display: 'inline-flex',
          }}>
          {mode === 'dark' ? (
            <Sun color="primary" sx={{ width: 20 }} />
          ) : (
            <Moon color="primary" sx={{ width: 20 }} />
          )}
        </IconButton>

        <MarketplaceButton />

        <Tooltip title={t('common:help')}>
          <IconButton
            color="inherit"
            onClick={() => window.open(helpjuiceUrl(), '_blank')}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
            }}>
            <Question color="primary" sx={{ width: 20 }} />
          </IconButton>
        </Tooltip>
        <AccountPopover />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
