import filters from './filters.json';
import table from './table.json';
import details from './details.json';
import tab from './tab.json';

export default {
  filters,
  table,
  details,
  tab,
};
