import { subject } from '@casl/ability';
import { useTranslation } from 'react-i18next';
import { ActionsTableMenu } from 'src/components/atoms/actions-table-menu';
import { AdminInvitationStatus } from 'src/features/site-management/types/enum/admin-invitation-status';
import { usePermissions } from 'src/permissions/can';
import { UserRole } from 'src/types/enum/user-role';

interface Props {
  status: AdminInvitationStatus;
  onEditPhoneNumberActionClick: () => void;
  onEditRoleActionClick: () => void;
  onResendInvitationActionClick: () => void;
  onRemoveAdminActionClick: () => void;
  adminToken: string;
  adminRole: UserRole;
  isCurrentAdminSelfIdentityEqual: boolean;
}

const AdminsActionsTableMenu = ({
  status,
  onEditPhoneNumberActionClick,
  onEditRoleActionClick,
  onRemoveAdminActionClick,
  onResendInvitationActionClick,
  isCurrentAdminSelfIdentityEqual,
  adminToken,
  adminRole,
}: Props) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const actions = [
    {
      id: 'edit-phone-number',
      label: t('siteManagement:administrators.actions.editPhoneNumber'),
      onClick: onEditPhoneNumberActionClick,
      disabled: permissions.cannot(
        'updatePhoneNumber',
        subject('SiteAdministrators', {
          userToken: adminToken,
          role: adminRole,
        }),
      ),
      tooltip: t('common:notSufficientPermissions'),
    },
    {
      id: 'edit-role',
      label: t('siteManagement:administrators.actions.editRole'),
      onClick: onEditRoleActionClick,
      disabled:
        permissions.cannot(
          'updateRole',
          subject('SiteAdministrators', {
            userToken: adminToken,
            role: adminRole,
          }),
        ) || status === AdminInvitationStatus.PENDING,
      tooltip: isCurrentAdminSelfIdentityEqual
        ? t('common:youCantChangeYourRole')
        : status === AdminInvitationStatus.PENDING
          ? t('siteManagement:administrators.pending')
          : t('common:notSufficientPermissions'),
    },
    {
      id: 'remove',
      label: t('common:button.remove'),
      onClick: onRemoveAdminActionClick,
      disabled: permissions.cannot(
        'delete',
        subject('SiteAdministrators', {
          userToken: adminToken,
          role: adminRole,
        }),
      ),
      tooltip: isCurrentAdminSelfIdentityEqual
        ? t('common:youCantRemoveYourself')
        : t('common:notSufficientPermissions'),
    },
    {
      id: 'resend-invitation',
      label: t('common:button.resend'),
      onClick: onResendInvitationActionClick,
      disabled:
        permissions.cannot(
          'resendInvitation',
          subject('SiteAdministrators', {
            userToken: adminToken,
            role: adminRole,
          }),
        ) || status === AdminInvitationStatus.ACCEPTED,
      tooltip: isCurrentAdminSelfIdentityEqual
        ? t('common:youCantResendInvitationToYourself')
        : status === AdminInvitationStatus.ACCEPTED
          ? t('siteManagement:administrators.alreadyAccepted')
          : t('common:notSufficientPermissions'),
    },
  ];

  return <ActionsTableMenu actions={actions} />;
};

export default AdminsActionsTableMenu;
