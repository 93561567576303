export enum StorageKeys {
  DIRECTORY_TOKEN = 'directoryToken',
  ADMIN_TOKEN = 'adminToken',
  ROLE = 'role',
  MEILISEARCH_TOKEN = 'meilisearchToken',
  VIEW_CONTEXT = 'viewContext',
}

export class StorageService {
  directoryToken = {
    get: () => StorageService.getItem(StorageKeys.DIRECTORY_TOKEN),
    set: (value: string) =>
      StorageService.setItem(StorageKeys.DIRECTORY_TOKEN, value),
    remove: () => StorageService.removeItem(StorageKeys.DIRECTORY_TOKEN),
  };

  static setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
