import { Button, Menu, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAvailableTenants } from 'src/hooks/api/use-available-tenants';
import { usePopover } from 'src/hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from 'src/icons/chevron-down';
import { useUserStore } from 'src/store/user-store';
import { UserRole } from 'src/types/enum/user-role';

export const TenantSelector = () => {
  const {
    setIsChangingDirectory,
    setDirectoryToken,
    setAdminToken,
    setRole,
    directoryToken,
  } = useUserStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data } = useAvailableTenants();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const selectedTenant = data?.resources.find(
    tenant => tenant.token === directoryToken,
  );

  const handleTenantChange = (
    tenantToken: string,
    adminToken: string,
    role: UserRole,
  ) => {
    setIsChangingDirectory(true);
    setDirectoryToken(tenantToken);
    setAdminToken(adminToken);
    setRole(role);

    handleClose();

    queryClient.invalidateQueries();

    navigate('/organization-details');
  };

  return (
    <>
      <Button
        color="primary"
        // disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        variant="text"
        endIcon={<ChevronDownIcon fontSize="small" />}
        sx={{
          alignItems: 'center',
        }}>
        {selectedTenant ? selectedTenant?.name : '---'}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {data?.resources.map(tenant => {
          return (
            <MenuItem
              key={`${tenant.tenantAdminToken}-${tenant.token}`}
              onClick={() => {
                handleTenantChange(
                  tenant.token,
                  tenant.tenantAdminToken,
                  tenant.role,
                );
              }}>
              {`${tenant.name} - ${tenant.siteName}`}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
