import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery, Theme, styled } from '@mui/material';
// import { Navbar } from "../../components/organisms/Navbar";
import { Footer } from 'src/components/molecules/footer';
import { Navbar } from 'src/components/organisms/navbar';
import { Sidebar } from 'src/components/organisms/sidebar';
import { useSettingsStore } from 'src/store/settings-store';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 64,
}));

export const Layout: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const settingsPinSidebar = useSettingsStore(state => state.pinSidebar);

  const LayoutContent = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: settingsPinSidebar ? '270px' : '73px',
    },
  }));

  return (
    <LayoutRoot>
      <Navbar />
      {!mdDown && <Sidebar pinned={settingsPinSidebar} />}
      <LayoutContent
        sx={{
          ml: {
            md: settingsPinSidebar ? '270px' : '73px',
          },
        }}>
        <Outlet />
        <Footer />
      </LayoutContent>
    </LayoutRoot>
  );
};
