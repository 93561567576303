import { ContactFormIcon } from 'src/icons/navigation-icons/contact-form-icon';
import { EmployeesIcon } from 'src/icons/navigation-icons/employees-icon';
import { InvitationSettingsIcon } from 'src/icons/navigation-icons/invitation-settings-icon';
import { MobileAppManagementIcon } from 'src/icons/navigation-icons/mobile-app-management';
import { OrganizationDetailsIcon } from 'src/icons/navigation-icons/organization-details-icon';
import { PasswordIcon } from 'src/icons/navigation-icons/password-icon';
import { RouteTitle, SidebarRouteTitle } from 'src/types/enum/route-title';
import { RoutesGroup } from 'src/types/interfaces/routes-group';
import { EventsIcon } from '../../icons/navigation-icons/events-icon';
import { MessagesIcon } from '../../icons/navigation-icons/messages-icon';
import { PollsIcon } from '../../icons/navigation-icons/polls-icon';
import { PostsIcon } from '../../icons/navigation-icons/posts-icon';
import { SiteDetailsIcon } from '../../icons/navigation-icons/site-details-icon';

export const getRoutes = (): RoutesGroup[] => [
  {
    title: SidebarRouteTitle.SETUP,
    routes: [
      {
        icon: EmployeesIcon,
        title: RouteTitle.EMPLOYEES,
        href: '/employees',
        id: 'employees_navigation_link',
      },
      {
        icon: MobileAppManagementIcon,
        title: RouteTitle.MOBILE_APP_MANAGEMENT,
        href: '/mobile-app-management',
        id: 'mobile_app_management_navigation_link',
      },
      {
        icon: InvitationSettingsIcon,
        title: RouteTitle.INVITATION_SETTINGS,
        href: '/invitation-settings',
        id: 'invitation_settings_navigation_link',
      },
    ],
  },
  {
    title: SidebarRouteTitle.PUBLICATIONS,
    routes: [
      {
        icon: PollsIcon,
        title: RouteTitle.POLLS,
        href: '/publications/polls',
        id: 'publications_polls_navigation_link',
      },
      {
        icon: PostsIcon,
        title: RouteTitle.POSTS,
        href: '/publications/posts',
        id: 'publications_posts_navigation_link',
      },
      {
        icon: EventsIcon,
        title: RouteTitle.EVENTS,
        href: '/publications/events',
        id: 'publications_events_navigation_link',
      },
      {
        icon: MessagesIcon,
        title: RouteTitle.MESSAGES,
        href: '/publications/messages',
        id: 'publications_messages_navigation_link',
      },
    ],
  },
  {
    title: SidebarRouteTitle.PROFILE,
    routes: [
      {
        icon: OrganizationDetailsIcon,
        title: RouteTitle.ORGANIZATION_DETAILS,
        href: '/organization-details',
        id: 'organization-details_navigation_link',
      },
      {
        icon: SiteDetailsIcon,
        title: RouteTitle.SITE_DETAILS,
        href: '/site-details',
        id: 'site-details_navigation_link',
      },
    ],
  },
  {
    title: SidebarRouteTitle.SUPPORT_AND_SETTINGS,
    routes: [
      {
        icon: PasswordIcon,
        title: RouteTitle.PASSWORD,
        href: '/password',
        id: 'password_navigation_link',
      },
      {
        icon: ContactFormIcon,
        title: RouteTitle.CONTACT_FORM,
        href: '/contact-form',
        id: 'contact_form_navigation_link',
      },
      {
        icon: MessagesIcon,
        title: RouteTitle.AUDIT_LOGS,
        href: '/audit-logs',
        id: 'tenant-audit-logs_navigation_link',
      },
    ],
  },
];
