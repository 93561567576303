import { TableCell, TableRow, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { TenantInvitationStatus } from 'src/features/site-tenants/types/enums/tenant-invitation-status';
import { TenantsActionSlotPayload } from 'src/features/site-tenants/types/payloads/tenants-action-slot-payload';
import TenantInvitationState from '../../molecules/tenant-invitation-state';

interface Props {
  name: string;
  // users: number;
  email: string;
  phoneNumber: string;
  status: TenantInvitationStatus;
  invitationToken: string;
  createdAt: Date;
  tenantToken: string;
  actionsSlot: (payload: TenantsActionSlotPayload) => ReactNode;
}

const TenantsTableRow: FC<Props> = ({
  name,
  // users,
  email,
  phoneNumber,
  status,
  invitationToken,
  createdAt,
  tenantToken,
  actionsSlot,
}) => {
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        //TODO: Add navigation to tenant details page
        navigate(`/tenants/${tenantToken}/permissions`);
      }}>
      <TableCell>
        <Typography variant="inherit">{name}</Typography>
      </TableCell>
      {/* TODO: Add users row when api is ready */}
      {/* <TableCell>
        <Typography variant="inherit">{users}</Typography>
      </TableCell> */}
      <TableCell>
        <Typography variant="inherit">{email}</Typography>
      </TableCell>
      <TableCell>
        {phoneNumber ? (
          <Typography variant="inherit">{phoneNumber}</Typography>
        ) : (
          <Typography color="textSecondary" variant="inherit">
            N/A
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <TenantInvitationState
          invitationStatus={status}
          invitationDate={new Date(createdAt ?? new Date())}
        />
      </TableCell>
      <TableCell align="right" sx={{ cursor: 'default' }}>
        {actionsSlot({
          name,
          email,
          invitationToken,
          status,
          phoneNumber,
          tenantToken,
        })}
      </TableCell>
    </TableRow>
  );
};

export default TenantsTableRow;
