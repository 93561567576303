import auditLogs from 'src/features/audit-logs/locales/en';
import createSite from 'src/features/create-site/locales/en';
import events from 'src/features/events/locales/en';
import marketplace from 'src/features/marketplace/locales/en';
import messages from 'src/features/messages/locales/en';
import password from 'src/features/password/locales/en';
import polls from 'src/features/polls/locales/en';
import siteApplications from 'src/features/site-applications/locales/en';
import siteAssets from 'src/features/site-assets/locales/en';
import siteAuditLogs from 'src/features/site-audit-logs/locales/en';
import siteDetails from 'src/features/site-details/locales/en';
import siteEnvironmentalIndicators from 'src/features/site-environmental-indicators/locales/en';
import siteEvents from 'src/features/site-events/locales/en';
import siteFaqs from 'src/features/site-faqs/locales/en';
import siteManagement from 'src/features/site-management/locales/en';
import siteMessages from 'src/features/site-messages/locales/en';
import siteMobileAppManagement from 'src/features/site-mobile-app-management/locales/en';
import sitePermissions from 'src/features/site-permissions/locales/en';
import sitePlaces from 'src/features/site-places/locales/en';
import sitePolls from 'src/features/site-polls/locales/en';
import sitePosts from 'src/features/site-posts/locales/en';
import siteTenants from 'src/features/site-tenants/locales/en';
import site from 'src/features/site/locales/en';
import tenantAuditLogs from 'src/features/tenant-audit-logs/locales/en';
import tenantContactForm from 'src/features/tenant-contact-form/locales/en';
import tenantDetails from 'src/features/tenant-details/locales/en';
import tenantDomains from 'src/features/tenant-domains/locales/en';
import tenantEmployeeJoinMethod from 'src/features/tenant-employee-join-method/locales/en';
import tenantEmployeesPermissions from 'src/features/tenant-employees-permissions/locales/en';
import tenantEmployees from 'src/features/tenant-employees/locales/en';
import tenantEvents from 'src/features/tenant-events/locales/en';
import tenantManageInvitationDialogue from 'src/features/tenant-manage-invitation-dialogue/locales/en';
import tenantMessages from 'src/features/tenant-messages/locales/en';
import tenantPolls from 'src/features/tenant-polls/locales/en';
import tenantPosts from 'src/features/tenant-posts/locales/en';
import applications from './applications.json';
import auth from './auth.json';
import common from './common.json';
import components from './components.json';
import invitationSettings from './invitation-settings.json';
import organizationDetails from './organization-details.json';
import publications from './publications.json';
import routes from './routes.json';
import validation from './validation.json';

const resources = {
  common,
  auth,
  validation,
  tenantPolls,
  sitePolls,
  tenantEmployees,
  applications,
  organizationDetails,
  invitationSettings,
  tenantDomains,
  tenantEmployeeJoinMethod,
  tenantContactForm,
  routes,
  tenantEmployeesPermissions,
  tenantManageInvitationDialogue,
  tenantPosts,
  tenantEvents,
  siteTenants,
  siteManagement,
  sitePlaces,
  publications,
  events,
  siteEvents,
  sitePosts,
  polls,
  siteDetails,
  siteEnvironmentalIndicators,
  siteApplications,
  sitePermissions,
  createSite,
  site,
  siteAssets,
  siteMobileAppManagement,
  password,
  tenantDetails,
  siteFaqs,
  siteMessages,
  messages,
  tenantMessages,
  marketplace,
  components,
  auditLogs,
  tenantAuditLogs,
  siteAuditLogs,
} as const;

export default resources;
