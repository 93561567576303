export const PlacesIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.16663 8.75C8.40927 8.75 9.41663 7.74264 9.41663 6.5C9.41663 5.25736 8.40927 4.25 7.16663 4.25C5.92398 4.25 4.91663 5.25736 4.91663 6.5C4.91663 7.74264 5.92398 8.75 7.16663 8.75Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16663 15.5C10.1666 12.5 13.1666 9.81371 13.1666 6.5C13.1666 3.18629 10.4803 0.5 7.16663 0.5C3.85292 0.5 1.16663 3.18629 1.16663 6.5C1.16663 9.81371 4.16663 12.5 7.16663 15.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
