import { ComponentType, Suspense, lazy } from 'react';
import type { DataRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from 'src/components/guards/auth-guard';
import { LoadingScreen } from 'src/components/loading-screen';
import Administrators from 'src/features/site-management/components/templates/administrators/administrators';
import Tenants from 'src/features/site-tenants/components/templates/tenants/tenants';
import { Layout } from 'src/layout/main-layout';

const Loadable =
  <T extends object>(Component: ComponentType<T>): ComponentType<T> =>
  props => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const TenantsContainer = Loadable(
  lazy(() =>
    import('../../features/site-tenants/containers/tenants').then(module => ({
      default: module.TenantsContainer,
    })),
  ),
);

const TenantDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-tenants/containers/tenant/[tenantToken]').then(
      module => ({
        default: module.TenantDetailsContainer,
      }),
    ),
  ),
);

const TenantPermissionsContainer = Loadable(
  lazy(() =>
    import('../../features/site-tenants/containers/tenant/permissions').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const SiteManagementContainer = Loadable(
  lazy(() =>
    import('../../features/site-management/containers').then(module => ({
      default: module.SiteManagementContainer,
    })),
  ),
);

const PublicationsPlacesContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/places-tabs').then(
      module => ({
        default: module.PublicationsPlacesContainer,
      }),
    ),
  ),
);

const PlaceTypesContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/place-types').then(
      module => ({
        default: module.PlaceTypesContainer,
      }),
    ),
  ),
);

const PlacesContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/places').then(module => ({
      default: module.PlacesPage,
    })),
  ),
);

const PlaceDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/places/[placeToken]').then(
      module => ({
        default: module.PlaceDetailsContainer,
      }),
    ),
  ),
);

const EditPlaceContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/places/edit').then(
      module => ({
        default: module.EditPlaceContainer,
      }),
    ),
  ),
);

const CreatePlacesContainer = Loadable(
  lazy(() =>
    import('../../features/site-places/containers/places/create').then(
      module => ({
        default: module.CreatePlaceContainer,
      }),
    ),
  ),
);

const Polls = Loadable(
  lazy(() =>
    import('../../features/site-polls/containers/polls').then(module => ({
      default: module.PollsContainer,
    })),
  ),
);

const PollDetails = Loadable(
  lazy(() =>
    import('../../features/site-polls/containers/[poll-id]').then(module => ({
      default: module.PollDetailsContainer,
    })),
  ),
);

const CreatePoll = Loadable(
  lazy(() =>
    import('../../features/site-polls/containers/create-poll').then(module => ({
      default: module.CreatePollContainer,
    })),
  ),
);

const EditPoll = Loadable(
  lazy(() =>
    import('../../features/site-polls/containers/edit-poll').then(module => ({
      default: module.EditPollContainer,
    })),
  ),
);

const PostsContainer = Loadable(
  lazy(() =>
    import('../../features/site-posts/containers/posts/posts').then(module => ({
      default: module.PostsPage,
    })),
  ),
);

const PostDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-posts/containers/posts/[postToken]').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const CreatePostContainer = Loadable(
  lazy(() =>
    import('../../features/site-posts/containers/posts/create').then(
      module => ({
        default: module.CreatePostContainer,
      }),
    ),
  ),
);

const EditPostContainer = Loadable(
  lazy(() =>
    import('../../features/site-posts/containers/posts/edit').then(module => ({
      default: module.EditPostContainer,
    })),
  ),
);

const EventsContainer = Loadable(
  lazy(() =>
    import('../../features/site-events/containers/events').then(module => ({
      default: module.EventsContainer,
    })),
  ),
);

const EventDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-events/containers/[eventToken]').then(
      module => ({
        default: module.EventDetailsContainer,
      }),
    ),
  ),
);

const CreateEventContainer = Loadable(
  lazy(() =>
    import('../../features/site-events/containers/create-event').then(
      module => ({
        default: module.CreateEventContainer,
      }),
    ),
  ),
);

const EditEventContainer = Loadable(
  lazy(() =>
    import('../../features/site-events/containers/edit-event').then(module => ({
      default: module.EditEventContainer,
    })),
  ),
);

const SiteDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-details/containers/site-details').then(
      module => ({
        default: module.SiteDetailsContainer,
      }),
    ),
  ),
);

// Environmental Indicators
const PublicationsIndicatorsContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicators-tabs'
    ).then(module => ({
      default: module.PublicationsIndicatorsContainer,
    })),
  ),
);

const IndicatorTypesContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicator-types'
    ).then(module => ({
      default: module.IndicatorTypesContainer,
    })),
  ),
);

const IndicatorsContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicators'
    ).then(module => ({
      default: module.IndicatorsContainer,
    })),
  ),
);

const CreateIndicatorContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicators/create'
    ).then(module => ({
      default: module.CreateIndicatorContainer,
    })),
  ),
);

const IndicatorDetailsContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicators/[indicatorToken]'
    ).then(module => ({
      default: module.IndicatorDetailsContainer,
    })),
  ),
);

const EditIndicatorContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-environmental-indicators/containers/indicators/edit'
    ).then(module => ({
      default: module.EditIndicatorContainer,
    })),
  ),
);

const MessagesContainer = Loadable(
  lazy(() =>
    import('../../features/site-messages/containers/messages').then(module => ({
      default: module.default,
    })),
  ),
);

const MessageDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-messages/containers/[messageToken]').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const EditMessageContainer = Loadable(
  lazy(() =>
    import('../../features/site-messages/containers/edit-message').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const CreateMessageContainer = Loadable(
  lazy(() =>
    import('../../features/site-messages/containers/create-message').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const MobileAppManagementContainer = Loadable(
  lazy(() =>
    import(
      '../../features/site-mobile-app-management/container/mobile-app-management'
    ).then(module => ({
      default: module.MobileAppManagementContainer,
    })),
  ),
);

const ApplicationsContainer = Loadable(
  lazy(() =>
    import('../../features/site-applications/containers/applications').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const ApplicationDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-applications/containers/[appToken]').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const PermissionsContainer = Loadable(
  lazy(() =>
    import('../../features/site-permissions/containers/permissions').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

const VisualsContainer = Loadable(
  lazy(() =>
    import('../../features/site-assets/containers/visuals').then(module => ({
      default: module.VisualsContainer,
    })),
  ),
);

const GdprContainer = Loadable(
  lazy(() =>
    import('../../features/site-details/containers/gdpr').then(module => ({
      default: module.GdprContainer,
    })),
  ),
);

const FaqsContainer = Loadable(
  lazy(() =>
    import('../../features/site-faqs/containers/faqs').then(module => ({
      default: module.default,
    })),
  ),
);

const FaqDetailsContainer = Loadable(
  lazy(() =>
    import('../../features/site-faqs/containers/[faqToken]').then(module => ({
      default: module.default,
    })),
  ),
);

const CreateFaqContainer = Loadable(
  lazy(() =>
    import('../../features/site-faqs/containers/create-faq').then(module => ({
      default: module.default,
    })),
  ),
);

const EditFaqContainer = Loadable(
  lazy(() =>
    import('../../features/site-faqs/containers/edit-faq').then(module => ({
      default: module.default,
    })),
  ),
);

const SiteAuditLogsContainer = Loadable(
  lazy(() =>
    import('../../features/site-audit-logs/containers/site-audit-logs').then(
      module => ({
        default: module.SiteAuditLogsContainer,
      }),
    ),
  ),
);

const routes: DataRouteObject[] = [
  {
    path: '/',
    id: 'tenants',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: '/tenants',
        path: '/',
        element: <Navigate to="/tenants" replace />,
      },
    ],
  },
  {
    id: 'tenants-container',
    path: 'tenants',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'tenants',
        path: '/tenants',
        element: <TenantsContainer />,
        children: [
          {
            id: 'tenants',
            index: true,
            element: <Tenants />,
          },
        ],
      },
      {
        id: 'tenant-details',
        path: '/tenants/:tenantToken/permissions',
        element: <TenantDetailsContainer />,
        children: [
          {
            id: 'tenant-permissions',
            index: true,
            element: <TenantPermissionsContainer />,
          },
        ],
      },
    ],
  },
  {
    id: 'site-management-container',
    path: 'site-management',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'site-management',
        path: '/site-management',
        element: <SiteManagementContainer />,
        children: [
          {
            id: 'site-management',
            index: true,
            element: <SiteDetailsContainer />,
          },
          {
            id: 'administrators',
            path: '/site-management/administrators',
            element: <Administrators />,
          },
        ],
      },
    ],
  },
  {
    id: 'site-mobile-app-management-container',
    path: 'mobile-app-management',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'mobile-app-management',
        path: '/mobile-app-management',
        element: <MobileAppManagementContainer />,
        children: [
          {
            id: 'mobile-app-management-applications',
            index: true,
            element: <ApplicationsContainer />,
          },
          {
            id: 'mobile-app-management-permissions',
            path: '/mobile-app-management/permissions',
            element: <PermissionsContainer />,
          },
          {
            id: 'visuals-container',
            path: '/mobile-app-management/visuals',
            element: <VisualsContainer />,
          },
          {
            id: 'gdpr-container',
            path: '/mobile-app-management/gdpr',
            element: <GdprContainer />,
          },
        ],
      },
      {
        id: 'mobile-app-management-application-details',
        path: '/mobile-app-management/applications/:appToken',
        element: <ApplicationDetailsContainer />,
      },
      {
        id: 'faqs-container',
        path: '/mobile-app-management/faqs',
        element: <FaqsContainer />,
      },
      {
        id: 'faq-details',
        path: '/mobile-app-management/faqs/:faqToken',
        element: <FaqDetailsContainer />,
      },
      {
        id: 'create-faq',
        path: '/mobile-app-management/faqs/create',
        element: <CreateFaqContainer />,
      },
      {
        id: 'edit-faq',
        path: '/mobile-app-management/faqs/:faqToken/edit',
        element: <EditFaqContainer />,
      },
    ],
  },
  {
    id: 'publications',
    path: 'publications',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'publications-events',
        path: '/publications/events',
        element: <EventsContainer />,
      },
      {
        id: 'publications-polls',
        path: '/publications/polls',
        element: <Polls />,
      },
      {
        id: 'publications-posts',
        path: '/publications/posts',
        element: <PostsContainer />,
      },
      {
        id: 'publications-messages',
        path: '/publications/messages',
        element: <MessagesContainer />,
      },
      {
        id: 'publications-places-tabs',
        path: '/publications/places',
        element: <PublicationsPlacesContainer />,
        children: [
          {
            id: 'publications-places',
            index: true,
            element: <PlacesContainer />,
          },
          {
            id: 'publications-place-types',
            path: '/publications/places/types',
            element: <PlaceTypesContainer />,
          },
        ],
      },
      {
        id: 'environmental-indicators-tabs',
        path: '/publications/environmental-indicators',
        element: <PublicationsIndicatorsContainer />,
        children: [
          {
            id: 'environmental-indicators',
            index: true,
            element: <IndicatorsContainer />,
          },
          {
            id: 'environmental-indicators-types',
            path: '/publications/environmental-indicators/types',
            element: <IndicatorTypesContainer />,
          },
        ],
      },
      {
        id: 'publications-posts-create',
        path: '/publications/posts/create',
        element: <CreatePostContainer />,
      },
      {
        id: 'publications-posts-details',
        path: '/publications/posts/:postToken',
        element: <PostDetailsContainer />,
      },
      {
        id: 'publications-posts-edit',
        path: '/publications/posts/:postToken/edit',
        element: <EditPostContainer />,
      },
      {
        id: 'publications-places',
        path: '/publications/places/create',
        element: <CreatePlacesContainer />,
      },
      {
        id: 'publications-places',
        path: '/publications/places/:placeToken',
        element: <PlaceDetailsContainer />,
      },
      {
        id: 'publications-places',
        path: '/publications/places/:placeToken/edit',
        element: <EditPlaceContainer />,
      },
      {
        id: 'publications-events',
        path: '/publications/events/create',
        element: <CreateEventContainer />,
      },
      {
        id: 'publications-events-details',
        path: '/publications/events/:eventToken',
        element: <EventDetailsContainer />,
      },
      {
        id: 'publications-events-edit',
        path: '/publications/events/:eventToken/edit',
        element: <EditEventContainer />,
      },
      {
        id: 'publications-create-poll',
        path: '/publications/polls/create',
        element: <CreatePoll />,
      },
      {
        id: 'publications-poll-details',
        path: '/publications/polls/:pollToken',
        element: <PollDetails />,
      },
      {
        id: 'publications-edit-poll',
        path: '/publications/polls/:pollToken/edit',
        element: <EditPoll />,
      },
      {
        id: 'publications-create-environmental-indicators',
        path: '/publications/environmental-indicators/create',
        element: <CreateIndicatorContainer />,
      },
      {
        id: 'publications-environmental-indicators-details',
        path: '/publications/environmental-indicators/:indicatorToken',
        element: <IndicatorDetailsContainer />,
      },
      {
        id: 'edit0publications-environmental-indicators',
        path: '/publications/environmental-indicators/:indicatorToken/edit',
        element: <EditIndicatorContainer />,
      },
      {
        id: 'publications-messages-details',
        path: '/publications/messages/:messageToken',
        element: <MessageDetailsContainer />,
      },
      {
        id: 'publications-messages-create',
        path: '/publications/messages/create',
        element: <CreateMessageContainer />,
      },
      {
        id: 'publications-messages-edit',
        path: '/publications/messages/:messageToken/edit',
        element: <EditMessageContainer />,
      },
    ],
  },
  {
    id: 'site-audit-logs-container',
    path: 'audit-logs',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: 'site-audit-logs',
        index: true,
        element: <SiteAuditLogsContainer />,
      },
    ],
  },
];

export default routes;
