import {
  ComponentType,
  Consumer,
  Context,
  ReactNode,
  createContext,
} from 'react';
import { createContextualCan, useAbility } from '@casl/react';
import { AppAbility, definePermissions } from './define-permissions';
import { UserRole } from 'src/types/enum/user-role';
import NotSufficientPermissions from 'src/components/atoms/not-sufficient-permissions';
import { ViewContext } from 'src/store/user-store';

export const AbilityContext = createContext<AppAbility | null>(null);

const rolesMap = {
  [UserRole.OWNER]: 'owner',
  [UserRole.ADMIN]: 'admin',
  [UserRole.MASTER]: 'master',
  [UserRole.VIEWER]: 'readonly',
} as const;

export const AbilityProvider = ({
  children,
  role,
  userToken,
  viewContext,
}: {
  children: ReactNode;
  role: UserRole;
  userToken: string;
  viewContext: ViewContext;
}) => {
  const ability = definePermissions(viewContext, rolesMap[role], userToken);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const Can = createContextualCan(
  AbilityContext.Consumer as Consumer<AppAbility>,
);

export const usePermissions = () => {
  const ability = useAbility(AbilityContext as Context<AppAbility>);

  if (!ability) {
    throw new Error('usePermissions must be used within a AbilityProvider.');
  }

  return ability;
};

export const withPermissions = <T extends JSX.IntrinsicAttributes>(
  shouldShowNotSufficientPermissions: (permissions: AppAbility) => boolean,
  Component: ComponentType<T>,
) => {
  return (props: T) => {
    const permissions = usePermissions();

    const showNotSufficientPermissions =
      shouldShowNotSufficientPermissions(permissions);

    if (showNotSufficientPermissions) {
      return <NotSufficientPermissions />;
    }

    return <Component {...props} />;
  };
};
