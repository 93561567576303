const searchStateDateRangeFilters: {
  [key: string]: (key: string, values: string[]) => string;
} = {
  from: (key: string, values: string[]) => `${key} ${values[0][0]} TO ${values[0][1]}`,
  to: (key: string, values: string[]) => `${key} ${values[0][0]} TO ${values[0][1]}`,
};

//buildSearchStateFilters is building filters object to request
export const buildSearchStateFilters = (filters: { [key: string]: string[] }): string[][] => {
  const filterState = Object.entries<string[]>(filters);

  const filterArray: string[][] = [];

  for (const [key, values] of filterState) {
    const facetTypeFilters: string[] = [];

    const buildSearchStateDateRangeFilters = searchStateDateRangeFilters[key];

    if (!buildSearchStateDateRangeFilters) {
      for (const value of values) {
        facetTypeFilters.push(`${key} = ${value}`);
      }
    } else {
      facetTypeFilters.push(buildSearchStateDateRangeFilters(key, values));
    }

    filterArray.push(facetTypeFilters);
  }

  return filterArray;
};
