import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotSufficientPermissions = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: "300px",
      }}
    >
      <Typography>{t("common:notSufficientPermissions")}</Typography>
    </Box>
  );
};

export default NotSufficientPermissions;
