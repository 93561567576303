import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Settings {
  direction?: 'ltr' | 'rtl';
  language: 'en' | 'pl';
  pinSidebar: boolean;
  theme?: string;
  openedSidebarGroups: string[];
}

export interface SettingsStore extends Settings {
  saveSettings: (newSettings: Partial<Settings>) => void;
}

const initialSettings: Settings = {
  direction: 'ltr',
  language: 'en',
  pinSidebar: true,
  theme: 'light',
  openedSidebarGroups: [],
};

export const useSettingsStore = create(
  persist<SettingsStore>(
    set => ({
      direction: initialSettings.direction,
      language: initialSettings.language,
      pinSidebar: initialSettings.pinSidebar,
      theme: initialSettings.theme,
      openedSidebarGroups: initialSettings.openedSidebarGroups,
      saveSettings: settings => {
        set(settings);
      },
    }),
    { name: 'settings' },
  ),
);
