import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useResendAdminInvitation } from 'src/features/site-management/hooks/api/use-resend-admin-invitation';

interface Props extends DialogActions {
  selectedAdminToken: string;
  selectedAdminEmail: string;
}

const ResendAdminInvitationDialog = ({
  open,
  onCancel,
  selectedAdminToken,
  selectedAdminEmail,
}: Props) => {
  const { t } = useTranslation();

  const {
    mutate: resendAdminInvitation,
    isPending: isLoadingResendAdminInvitation,
  } = useResendAdminInvitation(onCancel);

  const onSubmit = () => {
    resendAdminInvitation(selectedAdminToken);
  };

  return (
    <Dialog
      open={open}
      title={t('common:areYouSure')}
      cancelButtonText={t('common:button.no')}
      onCancel={onCancel}
      onConfirm={onSubmit}
      variant="warning"
      isConfirmButtonSubmitting={isLoadingResendAdminInvitation}
      isConfirmButtonDisabled={!selectedAdminEmail}
      confirmButtonId="resend_invitation_confirm_button"
      confirmButtonText={t(
        'siteManagement:administrators.resendAdminInvitationDialog.yesResend',
      )}>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t(
          'siteManagement:administrators.resendAdminInvitationDialog.content',
          {
            email: selectedAdminEmail,
          },
        )}
      </Typography>
      <Typography variant="body2">
        {t(
          'siteManagement:administrators.resendAdminInvitationDialog.makeSure',
          {
            keyWord1: t(
              'siteManagement:administrators.resendAdminInvitationDialog.keyWord1',
            ),
            keyWord2: t(
              'siteManagement:administrators.resendAdminInvitationDialog.keyWord2',
            ),
          },
        )}
      </Typography>
    </Dialog>
  );
};

export default ResendAdminInvitationDialog;
