import type { ChangeEvent, FC, FocusEvent, KeyboardEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { InputBase, SxProps, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'src/hooks/use-debounce';
import { Search as SearchIcon } from '../../icons/search';

interface QueryProps {
  disabled?: boolean;
  onChange: (newQuery: string) => void;
  value: string;
  sx?: SxProps;
  searchAsType?: boolean;
  id?: string;
}

const QueryRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: 'background.paper',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  height: 38,
  width: '100%',
}));

const QueryComponent: FC<QueryProps> = props => {
  const { disabled, onChange, value = '', searchAsType, id, ...other } = props;
  const [autoFocus, setAutoFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [tempValue, setTempValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  useEffect(() => {
    if (!disabled && autoFocus && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [autoFocus, disabled]);

  const debouncedRequest = useDebounce(() => {
    onChange(tempValue);
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTempValue(event.target.value);
    if (searchAsType) {
      debouncedRequest();
    }
  };

  const handleKeyup = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (!searchAsType && event.key === 'Enter' && onChange) {
      onChange(tempValue);
    }
  };

  const handleFocus = (): void => {
    setAutoFocus(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    /*
     There is a situation where an input goes from not disabled to disabled and DOM emits a blur
     event, with event as undefined. This means, that sometimes we'll receive an React Synthetic
     event and sometimes undefined because when DOM triggers the event, React is unaware of it,
     or it simply does not emit the event. To bypass this behaviour, we store a local variable
     that acts as autofocus.
     */

    if (event) {
      setAutoFocus(false);
    }
  };

  return (
    <QueryRoot {...other}>
      <SearchIcon
        sx={{
          color: 'text.secondary',
          ml: 2,
          mr: 1,
        }}
        fontSize="small"
      />
      <InputBase
        disabled={disabled}
        id={id}
        inputProps={{
          ref: inputRef,
          sx: {
            p: 0.75,
            '&::placeholder': {
              color: 'text.secondary',
              opacity: 1,
            },
          },
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyUp={handleKeyup}
        placeholder={t('common:filters.search')}
        sx={{ flex: 1 }}
        value={tempValue}
      />
    </QueryRoot>
  );
};

export const Query = React.memo(QueryComponent);
