import { InternalAxiosRequestConfig } from 'axios';
import { useUserStore } from 'src/store/user-store';

export const attachDirectoryToken = (config: InternalAxiosRequestConfig) => {
  if (config.skipTenantAttachment) {
    return config;
  }

  const userDirectoryToken = useUserStore.getState().directoryToken;

  if (userDirectoryToken) {
    config.url = `${userDirectoryToken}${config.url}`;
  }

  return config;
};
