import { createSvgIcon } from "@mui/material";

export const Info = createSvgIcon(
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12.5C22.5 15.1522 21.4464 17.6957 19.5711 19.5711C17.6957 21.4464 15.1522 22.5 12.5 22.5C9.84784 22.5 7.3043 21.4464 5.42893 19.5711C3.55357 17.6957 2.5 15.1522 2.5 12.5C2.5 9.84784 3.55357 7.3043 5.42893 5.42893C7.3043 3.55357 9.84784 2.5 12.5 2.5C15.1522 2.5 17.6957 3.55357 19.5711 5.42893C21.4464 7.3043 22.5 9.84784 22.5 12.5ZM13.75 7.5C13.75 7.83152 13.6183 8.14946 13.3839 8.38388C13.1495 8.6183 12.8315 8.75 12.5 8.75C12.1685 8.75 11.8505 8.6183 11.6161 8.38388C11.3817 8.14946 11.25 7.83152 11.25 7.5C11.25 7.16848 11.3817 6.85054 11.6161 6.61612C11.8505 6.3817 12.1685 6.25 12.5 6.25C12.8315 6.25 13.1495 6.3817 13.3839 6.61612C13.6183 6.85054 13.75 7.16848 13.75 7.5ZM11.25 11.25C10.9185 11.25 10.6005 11.3817 10.3661 11.6161C10.1317 11.8505 10 12.1685 10 12.5C10 12.8315 10.1317 13.1495 10.3661 13.3839C10.6005 13.6183 10.9185 13.75 11.25 13.75V17.5C11.25 17.8315 11.3817 18.1495 11.6161 18.3839C11.8505 18.6183 12.1685 18.75 12.5 18.75H13.75C14.0815 18.75 14.3995 18.6183 14.6339 18.3839C14.8683 18.1495 15 17.8315 15 17.5C15 17.1685 14.8683 16.8505 14.6339 16.6161C14.3995 16.3817 14.0815 16.25 13.75 16.25V12.5C13.75 12.1685 13.6183 11.8505 13.3839 11.6161C13.1495 11.3817 12.8315 11.25 12.5 11.25H11.25Z"
      fill="#4190DB"
    />
  </svg>,
  "Info",
);
