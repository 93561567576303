import { Box } from '@mui/material';
import { Query } from 'src/components/atoms/query';

interface TenantsSearchBarProps {
  query: string;
  handleChangeQuery: (query: string) => void;
}

const TenantsSearchBar = ({
  query,
  handleChangeQuery,
}: TenantsSearchBarProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gap: 2,
        gridTemplateColumns: {
          md: '1fr auto',
          sm: 'auto',
          xs: 'auto',
        },
        justifyItems: 'flex-start',
        px: 3,
        py: 3,
      }}>
      <Query
        searchAsType
        disabled={false}
        onChange={(newQuery: string) => {
          handleChangeQuery(newQuery);
        }}
        sx={{
          minWidth: 200,
          order: {
            sm: 2,
            xs: 1,
          },
        }}
        value={query}
      />
    </Box>
  );
};

export default TenantsSearchBar;
