export const EmployeesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5365 10.8423C13.6355 11.3841 14.5777 12.2535 15.2654 13.3406C15.4016 13.5559 15.4697 13.6636 15.4932 13.8126C15.5411 14.1155 15.33 14.488 15.0425 14.6078C14.901 14.6668 14.7419 14.6668 14.4236 14.6668M11.0269 7.65367C12.1453 7.10823 12.9139 5.97561 12.9139 4.66683C12.9139 3.35804 12.1453 2.22543 11.0269 1.67998M9.51722 4.66683C9.51722 6.50778 7.99646 8.00016 6.12051 8.00016C4.24456 8.00016 2.72381 6.50778 2.72381 4.66683C2.72381 2.82588 4.24456 1.3335 6.12051 1.3335C7.99646 1.3335 9.51722 2.82588 9.51722 4.66683ZM0.881455 13.1397C2.08488 11.3665 3.98389 10.2224 6.12051 10.2224C8.25714 10.2224 10.1562 11.3665 11.3596 13.1397C11.6232 13.5281 11.755 13.7224 11.7399 13.9705C11.728 14.1637 11.599 14.4002 11.4417 14.5169C11.2397 14.6668 10.9618 14.6668 10.4061 14.6668H1.83489C1.2792 14.6668 1.00136 14.6668 0.799337 14.5169C0.642038 14.4002 0.512994 14.1637 0.501177 13.9705C0.486001 13.7224 0.617819 13.5281 0.881455 13.1397Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
