import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'simplebar/dist/simplebar.min.css';
import App from './App.tsx';
import './i18n/index.ts';
//TODO: REfactor this
// import ApplicationStoreProvider from "./containers/contexts/application-store-context.tsx";
import { BrowserRouter } from 'react-router-dom';
import { Providers } from './components/providers.tsx';
import { AuthProvider } from './containers/contexts/auth-provider.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Providers>
          <App />
        </Providers>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
