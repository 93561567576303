import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { z } from 'zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserRole } from 'src/types/enum/user-role';
import { usePrompt } from 'src/hooks/use-prompt';
import { useEditAdminRole } from 'src/features/site-management/hooks/api/use-edit-admin-role';
import { usePermissions } from 'src/permissions/can';
import { subject } from '@casl/ability';

interface FormValues {
  role: UserRole;
}

interface Props extends DialogActions {
  selectedAdminToken: string;
  selectedAdminRole: UserRole;
}

const defaultValues = {
  role: UserRole.OWNER,
};

const EditAdminRoleDialog = ({
  open,
  onCancel,
  selectedAdminToken,
  selectedAdminRole,
}: Props) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const validationSchema = z
    .object({
      role: z.nativeEnum(UserRole),
    })
    .refine(
      value =>
        value.role !== selectedAdminRole &&
        permissions.can(
          'updateRole',
          subject('SiteAdministrators', {
            userToken: selectedAdminToken,
            role: selectedAdminRole,
            selectedRole: value.role,
          }),
        ),
    );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  usePrompt(t('common:areYouSureYouWantToLeaveThePage'), isDirty);

  const handleOnCancel = () => {
    onCancel?.();
    reset(defaultValues);
  };

  const { mutate: editAdminRole, isPending: isLoadingEditAdminRole } =
    useEditAdminRole(handleOnCancel);

  const onSubmit: SubmitHandler<FormValues> = ({ role }) => {
    editAdminRole({ siteAdminToken: selectedAdminToken, role });
  };

  const roleOptions = [
    {
      value: UserRole.OWNER,
      label: t('common:roles.owner'),
      description: t(
        'siteManagement:administrators.inviteAdminDialog.ownerDescription',
      ),
    },
    {
      value: UserRole.ADMIN,
      label: t('common:roles.admin'),
      description: t(
        'siteManagement:administrators.inviteAdminDialog.adminDescription',
      ),
    },
    {
      value: UserRole.VIEWER,
      label: t('common:roles.viewer'),
      description: t(
        'siteManagement:administrators.inviteAdminDialog.viewerDescription',
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      title={t('siteManagement:administrators.editAdminRoleDialog.title')}
      onCancel={handleOnCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText={t('common:button.save')}
      cancelButtonText={t('common:button.cancel')}
      confirmButtonId="edit_admin_role_confirm_button"
      isConfirmButtonSubmitting={isLoadingEditAdminRole}
      isConfirmButtonDisabled={!isValid}>
      <Controller
        name="role"
        render={({ field: { onChange, value, onBlur } }) => (
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
            defaultValue={UserRole.OWNER}
            onChange={onChange}
            value={value}
            onBlur={onBlur}>
            {roleOptions.map(option => (
              <Box key={option.value}>
                <FormControlLabel
                  disabled={
                    selectedAdminRole === option.value ||
                    permissions.cannot(
                      'updateRole',
                      subject('SiteAdministrators', {
                        userToken: selectedAdminToken,
                        role: selectedAdminRole,
                        selectedRole: option.value,
                      }),
                    )
                  }
                  label={option.label}
                  control={<Radio value={option.value} />}
                />
                <FormHelperText>{option.description}</FormHelperText>
              </Box>
            ))}
          </RadioGroup>
        )}
        control={control}
      />
      {errors?.root ? (
        <FormHelperText error>{errors?.root?.message}</FormHelperText>
      ) : null}
    </Dialog>
  );
};

export default EditAdminRoleDialog;
