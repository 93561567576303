import { useEffect } from 'react';
import { useUserStore } from 'src/store/user-store';
import { useAvailableTenants } from './api/use-available-tenants';
import { useAvailableSites } from './api/use-available-sites';

export const useSetInitialOrganizations = (isLoggedIn: boolean = true) => {
  const {
    directoryToken,
    setDirectoryToken,
    setAdminToken,
    setRole,
    viewContext,
  } = useUserStore();

  const { data: availableTenantsData, isLoading: isLoadingAvailableTenants } =
    useAvailableTenants(isLoggedIn);

  const { data: availableSitesData, isLoading: isLoadingAvailableSites } =
    useAvailableSites(isLoggedIn);

  const hasAvailableTenants =
    availableTenantsData && availableTenantsData?.resources.length >= 1;

  const hasAvailableSites =
    availableSitesData && availableSitesData?.resources.length >= 1;

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (hasAvailableTenants && !directoryToken) {
        setDirectoryToken(availableTenantsData?.resources?.[0]?.token);
      }
    }
    if (viewContext === 'site') {
      if (hasAvailableSites && !directoryToken) {
        setDirectoryToken(availableSitesData?.resources?.[0]?.token);
      }
    }
  }, [
    availableSitesData?.resources,
    availableTenantsData?.resources,
    hasAvailableSites,
    hasAvailableTenants,
    setDirectoryToken,
    directoryToken,
    viewContext,
  ]);

  useEffect(() => {
    if (viewContext === 'tenant') {
      if (hasAvailableTenants && isLoggedIn && !isLoadingAvailableTenants) {
        setRole(availableTenantsData?.resources?.[0]?.role);
        setAdminToken(availableTenantsData?.resources?.[0]?.tenantAdminToken);
      }
    } else {
      if (hasAvailableSites && isLoggedIn && !isLoadingAvailableSites) {
        setRole(availableSitesData?.resources?.[0]?.role);
        setAdminToken(availableSitesData?.resources?.[0]?.siteAdminToken);
      }
    }
  }, [
    hasAvailableTenants,
    availableTenantsData?.resources,
    isLoadingAvailableTenants,
    isLoggedIn,
    viewContext,
    setRole,
    setAdminToken,
    hasAvailableSites,
    isLoadingAvailableSites,
    availableSitesData?.resources,
  ]);

  return {
    isLoadingAvailableTenants,
    isLoadingAvailableSites,
  };
};
