import type { FC } from 'react';

import {
  Box,
  ButtonProps,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';

export interface Action {
  id: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  hidden?: boolean;
}

interface ActionsMenuProps extends ButtonProps {
  actions: Action[];
  id?: string;
}

export const ActionsTableMenu: FC<ActionsMenuProps> = ({ actions, id }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <Box onClick={e => e.stopPropagation()}>
      <IconButton onClick={handleOpen} ref={anchorRef} id={id}>
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {actions.map((action, i) => {
          if (action.hidden) {
            return null;
          }

          return action.tooltip ? (
            <Tooltip
              key={action.id}
              title={action.tooltip ?? ''}
              placement="left"
              disableHoverListener={!action?.disabled}>
              <span>
                <MenuItem
                  id={action.id}
                  onClick={() => {
                    action.onClick();
                    handleClose();
                  }}
                  disabled={action.disabled}>
                  {action.label}
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              id={action.id}
              key={i}
              onClick={() => {
                action.onClick();
                handleClose();
              }}
              disabled={action.disabled}>
              {action.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
