import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ApiError } from 'src/types/interfaces/api-error';
import toast from 'react-hot-toast';
import { delayedCallbackExecution } from 'src/utils/delayed-callback-execution';
import { siteTenantsKeys } from '../../api/query-keys/tenants';
import { removeInvitation } from '../../api/lib/tenants';

export const useRemoveInvitation = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (invitationToken: string) => removeInvitation(invitationToken),
    onSuccess: () => {
      delayedCallbackExecution(1000, () => {
        queryClient.invalidateQueries({
          queryKey: siteTenantsKeys.lists(),
        });
      });

      onSuccess?.();

      toast.success(t('siteTenants:tenants.removeInvitationDialog.success'));
    },
    onError: (error: ApiError) => {
      toast.error(
        error?.response.data?.message ||
          t('common:errorMessages.somethingWentWrong'),
      );
      throw error;
    },
  });
};
