export const EnvironmentalIndicatorsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.59895 9.72452C3.63229 10.3667 4.95142 9.99209 5.6806 9.72452C6.07818 8.92179 8.46363 10.9286 8.86121 8.92179C9.25878 6.91498 11.6442 7.31635 11.2467 5.7109C11.0534 4.93056 9.88066 3.69833 11.0179 2.63059M11.0179 2.63059C10.5771 2.54488 10.1219 2.5 9.65636 2.5C7.82349 2.5 6.15155 3.19561 4.88545 4.33959C3.42138 5.66245 2.5 7.58486 2.5 9.72452C2.5 10.1348 2.53387 10.537 2.59895 10.9286C2.9312 12.9279 4.07684 14.6487 5.6806 15.7325C5.9928 15.9434 6.32237 16.1303 6.66667 16.2903M11.0179 2.63059C13.5911 3.13095 15.6737 5.02282 16.4671 7.5"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.0931 17.4998C10.0931 17.2377 10.1684 16.8275 10.3691 16.3156M14.085 13.1577C12.1877 13.9472 10.7727 15.286 10.3691 16.3156M10.3691 16.3156C9.40086 14.2301 10.3103 10.7973 17.7054 10.4262C17.7804 10.4224 17.8258 10.5125 17.7758 10.5686C17.1751 11.2424 16.5016 12.3342 16.25 14.1665C15.8705 16.9297 12.7027 16.9097 11.6109 16.3156"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
