export const EventsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 6.42593H1.33337M10.963 0.5V3.46296M5.03708 0.5V3.46296M5.77782 10.8704L7.2593 12.3519L10.5926 9.01852M4.88893 15.3148H11.1112C12.3557 15.3148 12.978 15.3148 13.4534 15.0726C13.8715 14.8596 14.2114 14.5196 14.4245 14.1015C14.6667 13.6261 14.6667 13.0038 14.6667 11.7593V5.53704C14.6667 4.29248 14.6667 3.6702 14.4245 3.19484C14.2114 2.7767 13.8715 2.43674 13.4534 2.22369C12.978 1.98148 12.3557 1.98148 11.1112 1.98148H4.88893C3.64437 1.98148 3.02209 1.98148 2.54673 2.22369C2.12859 2.43674 1.78863 2.7767 1.57558 3.19484C1.33337 3.6702 1.33337 4.29248 1.33337 5.53704V11.7593C1.33337 13.0038 1.33337 13.6261 1.57558 14.1015C1.78863 14.5196 2.12859 14.8596 2.54673 15.0726C3.02209 15.3148 3.64437 15.3148 4.88893 15.3148Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
