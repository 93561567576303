import { lightError, lightSuccess, lightWarning } from 'src/colors';
import { TenantInvitationStatus } from '../types/enums/tenant-invitation-status';

export const invitationStateTranslations = {
  [TenantInvitationStatus.ACCEPTED]:
    'siteTenants:tenants.invitationStatus.accepted' as const,
  [TenantInvitationStatus.PENDING]:
    'siteTenants:tenants.invitationStatus.pending' as const,
};

export const invitationStateColor = {
  [TenantInvitationStatus.PENDING]: lightWarning.light,
  [TenantInvitationStatus.ACCEPTED]: lightSuccess.dark,
};

export const invitationStatusVariants = [
  {
    color: lightError.main,
    label: 'accepted',
    value: TenantInvitationStatus.ACCEPTED,
  },
  {
    color: lightWarning.light,
    label: 'pending',
    value: TenantInvitationStatus.PENDING,
  },
];
