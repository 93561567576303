export const SiteDetailsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1852 14.6668V2.81498C13.1852 1.99678 12.522 1.3335 11.7037 1.3335H4.29634C3.47814 1.3335 2.81486 1.99678 2.81486 2.81498V14.6668M13.1852 14.6668H14.6667M13.1852 14.6668H9.48152M2.81486 14.6668H1.33337M2.81486 14.6668H6.51856M9.48152 14.6668V10.9631C9.48152 10.554 9.14989 10.2224 8.74078 10.2224H7.2593C6.85019 10.2224 6.51856 10.554 6.51856 10.9631V14.6668M9.48152 14.6668H6.51856M5.77782 4.29644H7.2593M5.77782 7.25942H7.2593M8.74078 4.29644C9.31934 4.29644 9.64371 4.29644 10.2223 4.29644M8.74078 7.25942H10.2223"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
