import { Session, User } from '@supabase/supabase-js';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

import supabase from 'src/api/clients/supabase';
import { useLocation, useNavigate } from 'react-router-dom';

export interface AuthState {
  session: Session | null;
  loading: boolean;
  setSession: (session: Session | null) => void;
  setLoading: (loading: boolean) => void;
}

export const authStore = createStore<AuthState>(set => ({
  session: null,
  loading: true,
  setSession: session => {
    set({ session });
  },
  setLoading: loading => {
    set({ loading });
  },
}));

const AuthContext = createContext<StoreApi<AuthState> | null>(null);

const CHANGE_PASSWORD_PATHNAME = '/settings/change-password';

const EXCLUDED_ROUTES_FROM_NAVIGATING_TO_SET_UP_PASSWORD_SCREEN = [
  '/set-up-password',
  '/forgot-password',
  '/reset-password',
];

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const authStoreSetLoading = useStore(authStore, state => state.setLoading);
  const authStoreSetSession = useStore(authStore, state => state.setSession);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      authStoreSetSession(session);
      authStoreSetLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (
        _event === 'PASSWORD_RECOVERY' &&
        !EXCLUDED_ROUTES_FROM_NAVIGATING_TO_SET_UP_PASSWORD_SCREEN.includes(
          location.pathname,
        )
      ) {
        navigate('/set-up-password');
      }

      if (
        _event === 'PASSWORD_RECOVERY' &&
        location.pathname === '/forgot-password'
      ) {
        navigate('/reset-password');
      }

      authStoreSetSession(session);

      if (
        _event === 'USER_UPDATED' &&
        location.pathname !== CHANGE_PASSWORD_PATHNAME
      ) {
        navigate('/');
      }

      if (_event === 'SIGNED_OUT') {
        navigate('/login');
      }
    });

    return subscription.unsubscribe;
  }, [authStoreSetLoading, authStoreSetSession, location.pathname]);

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};

export const authStoreOutsideComponent = () => {
  return authStore.getState();
};

export const useAuthOutsideContext = () => {
  const store = useStore(authStore);

  return store;
};

export const useAuth = (): AuthState => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth has to be used inside AuthProvider');
  }

  const store = useStore(context);

  return store;
};

export const useUser = (): User | null => {
  const state = useAuth();

  return state.session?.user ?? null;
};
