export enum UserRole {
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
  MASTER = 'MASTER',
}

export enum UserRoleTranslations {
  MASTER = 'common:roles.master',
  ADMIN = 'common:roles.admin',
  OWNER = 'common:roles.owner',
  VIEWER = 'common:roles.viewer',
}
