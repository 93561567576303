import { useLocation } from "react-router-dom";
import { decodeAndParseQueryParams } from "src/features/tenant/hooks/meilisearch/utils/decode-and-parse-query-params";

export const useUrlQuery = () => {
  const location = useLocation();

  const urlSearch = location.search.slice(1);

  const parsedQuery = decodeAndParseQueryParams(urlSearch);

  return {
    get: function get<T>(value: string): T {
      // @ts-expect-error TODO:WE NEED TO FIND BETTER TYPING SOLUTION
      return parsedQuery[value];
    },
  };
};
