import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/store/user-store';
import { useAvailableTenants } from './api/use-available-tenants';
import { useAvailableSites } from './api/use-available-sites';
import { useAuth } from 'src/containers/contexts/auth-provider';

type ReturnType = { tenantContext: boolean; siteContext: boolean };

export const useSetViewContext = (): ReturnType => {
  const { viewContext, setViewContext, setIsLoadingViewContextAccess } =
    useUserStore();

  const auth = useAuth();
  const navigate = useNavigate();

  const [access, setAccess] = useState({
    tenantContext: false,
    siteContext: false,
  });

  const [hasFetchedAccess, setHasFetchedAccess] = useState(false);

  const availableTenants = useAvailableTenants(!!auth.session?.access_token);

  useEffect(() => {
    if (
      availableTenants.isSuccess &&
      availableTenants.data?.resources.length > 0
    ) {
      setAccess(prev => ({
        ...prev,
        tenantContext: true,
      }));
    }

    if (
      availableTenants.isError ||
      (!availableTenants.isPending &&
        availableTenants.data?.resources.length === 0)
    ) {
      setAccess(prev => ({
        ...prev,
        tenantContext: false,
      }));
    }
  }, [
    availableTenants.isSuccess,
    availableTenants.isError,
    availableTenants.data?.resources.length,
    availableTenants.isPending,
  ]);

  const availableSites = useAvailableSites(!!auth.session?.access_token);

  useEffect(() => {
    if (availableSites.isSuccess && availableSites.data?.resources.length > 0) {
      setAccess(prev => ({
        ...prev,
        siteContext: true,
      }));
    }

    if (
      availableSites.isError ||
      (!availableSites.isPending && availableSites.data?.resources.length === 0)
    ) {
      setAccess(prev => ({
        ...prev,
        siteContext: false,
      }));
    }
  }, [
    availableSites.isSuccess,
    availableSites.isError,
    availableSites.data?.resources.length,
    availableSites.isPending,
  ]);

  useEffect(() => {
    if (
      (availableTenants.isSuccess || availableTenants.isError) &&
      (availableSites.isSuccess || availableSites.isError)
    ) {
      setHasFetchedAccess(true);
    }
  }, [
    availableSites.isError,
    availableSites.isSuccess,
    availableTenants.isError,
    availableTenants.isSuccess,
  ]);

  useEffect(() => {
    if (hasFetchedAccess) {
      switch (true) {
        case access.siteContext &&
          !access.tenantContext &&
          viewContext !== 'site':
          setViewContext('site');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        case !access.siteContext &&
          access.tenantContext &&
          viewContext !== 'tenant':
          setViewContext('tenant');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        case access.siteContext && access.tenantContext && viewContext === null:
          setViewContext('tenant');
          setIsLoadingViewContextAccess(false);

          navigate('/');

          break;

        default:
          setIsLoadingViewContextAccess(false);
          break;
      }
    }
  }, [
    access.siteContext,
    access.tenantContext,
    navigate,
    setIsLoadingViewContextAccess,
    setViewContext,
    viewContext,
    hasFetchedAccess,
  ]);

  return access;
};
