import qs from 'qs';
import { Location } from 'react-router-dom';
import {
  MeiliSearchSearchState,
  MeiliSearchSearchStateRefactor,
} from 'src/features/tenant/hooks/meilisearch/types/meilisearch-search-state';

const createURL = (
  pathname: string,
  state: MeiliSearchSearchState | MeiliSearchSearchStateRefactor,
) => `${pathname}?${qs.stringify(state)}`;

export const searchStateToUrl = (
  location: Location,
  searchState: MeiliSearchSearchState | MeiliSearchSearchStateRefactor,
) => (searchState ? createURL(location.pathname, searchState) : '');
