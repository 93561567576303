import { useMutation, useQueryClient } from '@tanstack/react-query';
import { availableSitesKeys } from 'src/api/query-keys/site/available-sites.query-keys';
import { confirmSiteJoin } from 'src/api/lib/site';

export const useConfirmSiteJoin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => confirmSiteJoin(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: availableSitesKeys.all });
    },
  });
};
