import { useQuery } from '@tanstack/react-query';

import { generateTenantSearchKey } from '../../api/lib/meilisearch';
import { meilisearchSearchKeyKeys } from '../../api/query-keys/meilisearch-search-key';
import { useUserStore } from 'src/store/user-store';

export const useGenerateTenantSearchKey = (enabled = true) => {
  const { directoryToken } = useUserStore();

  return useQuery({
    queryFn: generateTenantSearchKey,
    queryKey: meilisearchSearchKeyKeys.detail(directoryToken ?? ''),
    enabled,
  });
};
