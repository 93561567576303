import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from 'src/icons/chevron-down';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/store/user-store';
import { UserRole } from 'src/types/enum/user-role';
import { useAvailableSites } from 'src/hooks/api/use-available-sites';

export const SiteSelector = () => {
  const {
    setIsChangingDirectory,
    setDirectoryToken,
    setAdminToken,
    setRole,
    directoryToken,
  } = useUserStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data } = useAvailableSites();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const selectedSite = data?.resources.find(
    site => site.token === directoryToken,
  );

  const handleChange = (
    siteToken: string,
    adminToken: string,
    role: UserRole,
  ) => {
    setIsChangingDirectory(true);
    setDirectoryToken(siteToken);
    setAdminToken(adminToken);
    setRole(role);

    handleClose();

    queryClient.invalidateQueries();

    navigate('/');
  };

  return (
    <>
      <Button
        color="primary"
        // disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        variant="text"
        endIcon={<ChevronDownIcon fontSize="small" />}
        sx={{
          alignItems: 'center',
        }}>
        {selectedSite?.name ?? '---'}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {data?.resources.map(site => {
          return (
            <MenuItem
              key={site.token}
              onClick={() => {
                handleChange(site.token, site.siteAdminToken, site.role);
              }}>
              {site.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
