import { AbilityBuilder } from '@casl/ability';
import { AppAbility, Role } from './define-permissions';

export const siteContextPermissions = (
  builder: AbilityBuilder<AppAbility>,
  role: Role,
  userToken: string,
) => {
  const { can } = builder;

  can('read', 'SiteTenants');

  can('read', 'OrganizationDetails');

  can('read', 'SiteAdministrators');

  can('read', 'SiteEvents');

  can('read', 'SiteReceptionDetails');

  can('read', 'SitePolls');

  can('read', 'SitePosts');

  can('read', 'SiteIndicators');

  can('read', 'SiteIndicatorTypes');

  can('read', 'SiteApplications');

  can('read', 'SitePermissions');

  can('read', 'TenantPermissions');

  can('read', 'SiteAssets');

  can('read', 'SiteGdpr');

  can('read', 'Password');
  can('update', 'Password');

  can('read', 'SiteFaqs');

  can('read', 'SiteMessages');

  can('read', 'SiteAuditLogs');

  if (role === 'admin' || role === 'owner' || role === 'master') {
    can('create', 'SiteTenants');
    can('update', 'SiteTenants');
    can('delete', 'SiteTenants');
    can('resendInvitation', 'SiteTenants');

    can('create', 'SiteEvents');
    can('update', 'SiteEvents');
    can('delete', 'SiteEvents');

    can('create', 'SiteReceptionDetails');
    can('update', 'SiteReceptionDetails');
    can('delete', 'SiteReceptionDetails');

    can('create', 'SitePolls');
    can('update', 'SitePolls');
    can('delete', 'SitePolls');

    can('create', 'SitePosts');
    can('update', 'SitePosts');
    can('delete', 'SitePosts');
    can('publish', 'SitePosts');
    can('unpublish', 'SitePosts');

    can('create', 'SiteIndicators');
    can('update', 'SiteIndicators');
    can('delete', 'SiteIndicators');

    can('create', 'SiteIndicatorTypes');
    can('update', 'SiteIndicatorTypes');
    can('delete', 'SiteIndicatorTypes');

    can('setAsDefault', 'SiteApplications');
    can('removeFromDefault', 'SiteApplications');
    can('grantPermission', 'SiteApplications');
    can('revokePermission', 'SiteApplications');

    can('grant', 'SitePermissions');
    can('revoke', 'SitePermissions');

    can('create', 'TenantPermissions');
    can('delete', 'TenantPermissions');

    can('update', 'SiteAssets');

    can('create', 'SiteGdpr');
    can('update', 'SiteGdpr');
    can('delete', 'SiteGdpr');

    can('create', 'SiteFaqs');
    can('update', 'SiteFaqs');
    can('delete', 'SiteFaqs');
    can('publish', 'SiteFaqs');
    can('unpublish', 'SiteFaqs');

    can('create', 'SiteMessages');
    can('update', 'SiteMessages');
    can('delete', 'SiteMessages');
    can('publish', 'SiteMessages');
    can('duplicate', 'SiteMessages');
  }

  if (role === 'owner' || role === 'master') {
    can('create', 'SiteAdministrators');
    can('updatePhoneNumber', 'SiteAdministrators');
    can('updateRole', 'SiteAdministrators', {
      userToken: { $ne: userToken },
    });
    can('delete', 'SiteAdministrators', {
      userToken: { $ne: userToken },
    });
    can('resendInvitation', 'SiteAdministrators', {
      userToken: { $ne: userToken },
    });
  }

  if (role === 'admin') {
    can('create', 'SiteAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('updatePhoneNumber', 'SiteAdministrators', {
      role: { $ne: 'OWNER' },
    });
    can('updateRole', 'SiteAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('delete', 'SiteAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('resendInvitation', 'SiteAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
    });
  }

  if (role === 'master') {
    can('create', 'Site');
  }
};
