import axios from 'axios';
import axiosRefreshToken from 'axios-auth-refresh';
import { attachMeilisearchApiKey } from '../interceptors/attach-meilisearch-api-key';
import { refreshMeilisearchApiKey } from '../interceptors/refresh-meilisearch-api-key';

const meiliSearchApiClient = axios.create({
  baseURL: import.meta.env.VITE_MEILI_URL,
});

meiliSearchApiClient.interceptors.request.use(attachMeilisearchApiKey);

axiosRefreshToken(meiliSearchApiClient, refreshMeilisearchApiKey, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true,
});

export default meiliSearchApiClient;
