import axios from 'axios';
import { attachAcceptLanguage } from 'src/api/interceptors/attach-accept-language';
import { attachAccessToken } from 'src/api/interceptors/attach-access-token';
import { attachDirectoryToken } from '../interceptors/attach-directory-token';

const siteApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/site-admin/v1/sites`,
});

siteApi.interceptors.request.use(attachAcceptLanguage);
siteApi.interceptors.request.use(attachAccessToken);
siteApi.interceptors.request.use(attachDirectoryToken);

export default siteApi;
