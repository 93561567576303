export const OrganizationDetailsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8933 16.6667H4.10742V10.1281H3.16188C2.55223 10.1281 2.26776 9.35496 2.72687 8.94685L8.99909 2.9058C9.56053 2.36473 10.4396 2.36473 11.0011 2.9058L17.2728 8.94685C17.7324 9.35442 17.4479 10.1281 16.8378 10.1281H15.8933V16.6667Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 16.6665H8.33337V13.6665C8.33337 12.562 9.07962 11.6665 10 11.6665C10.9205 11.6665 11.6667 12.562 11.6667 13.6665V16.6665Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
