import { FilterValue } from "../types/filter-value";

//Method to read and transform filters from URL
export const buildFiltersFromUrlParamsHandlers: {
  [key: string]: (param: string) => { key: string; value: FilterValue };
} = {
  " = ": (param: string) => {
    const [key, value] = param.split(" = ");

    return { key, value };
  },
  " TO ": (param: string) => {
    const [createdAtTimestamp, timestampTo] = param.split(" TO ");
    const [createdAt, timestampFrom] = createdAtTimestamp.split(" ");

    return { key: createdAt, value: [+timestampFrom, +timestampTo] };
  },
};
