import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText } from '@mui/material';
import { z } from 'zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneNumberInput from 'src/components/atoms/phone-number-input/phone-number-input';
import { usePrompt } from 'src/hooks/use-prompt';
import { useEditAdminPhoneNumber } from 'src/features/site-management/hooks/api/use-edit-admin-phone-number';
import { useEffect } from 'react';

interface FormValues {
  phoneNumber: string;
}

interface Props extends DialogActions {
  selectedAdminToken: string;
  selectedAdminPhoneNumber: string;
}

const defaultValues = {
  phoneNumber: '',
};

const EditAdminPhoneNumberDialog = ({
  open,
  onCancel,
  selectedAdminToken,
  selectedAdminPhoneNumber,
}: Props) => {
  const { t } = useTranslation();

  const validationSchema = z.object({
    phoneNumber: z.string().min(9, t('validation:phoneNumber')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  usePrompt(t('common:areYouSureYouWantToLeaveThePage'), isDirty);

  useEffect(() => {
    if (open) {
      reset({ phoneNumber: selectedAdminPhoneNumber });
    }
  }, [open, selectedAdminPhoneNumber, reset]);

  const handleOnCancel = () => {
    onCancel?.();
    reset(defaultValues);
  };

  const {
    mutate: editAdminPhoneNumber,
    isPending: isLoadingEditAdminPhoneNumber,
  } = useEditAdminPhoneNumber(handleOnCancel);

  const onSubmit: SubmitHandler<FormValues> = ({ phoneNumber }) => {
    editAdminPhoneNumber({
      siteAdminToken: selectedAdminToken,
      phoneNumber: `+${phoneNumber}`,
    });
  };

  return (
    <Dialog
      open={open}
      title={t(
        'siteManagement:administrators.editAdminPhoneNumberDialog.title',
      )}
      onCancel={handleOnCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText={t('common:button.save')}
      cancelButtonText={t('common:button.cancel')}
      confirmButtonId="edit_admin_phone_number_confirm_button"
      isConfirmButtonSubmitting={isLoadingEditAdminPhoneNumber}
      isConfirmButtonDisabled={!isValid}>
      <Box sx={{ width: '100%' }}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState }) => (
            <PhoneNumberInput
              label={t(
                'siteManagement:administrators.editAdminPhoneNumberDialog.phoneNumberInput',
              )}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              error={Boolean(fieldState.invalid && fieldState.isTouched)}
              helperText={fieldState.error?.message}
              required
            />
          )}
        />
      </Box>
      {errors?.root ? (
        <FormHelperText error>{errors?.root?.message}</FormHelperText>
      ) : null}
    </Dialog>
  );
};

export default EditAdminPhoneNumberDialog;
