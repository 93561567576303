import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { debounce } from 'src/utils/debounce';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { z } from 'zod';
import { InputField } from '../input-field';

interface Props {
  totalPages: number;
  changePage: (newPage: number) => void;
  page: number | null;
}

export interface FormValues {
  page: number;
}

const defaultValues = {
  page: 1,
};

const GoToPageInput = ({ totalPages, changePage, page }: Props) => {
  const { t } = useTranslation();

  const schema = z.object({
    page: z.coerce
      .number()
      .positive(t('validation:positive'))
      .min(1, t('validation:minNumber', { number: 1 }))
      .max(totalPages, t('validation:maxNumber', { number: totalPages })),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: FormValues) => {
    changePage(data.page);
  };

  const debouncedSubmit = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 500),
    [],
  );

  useEffect(() => {
    if (page) {
      reset({ page });
    }
  }, [page]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography
          color="textSecondary"
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
          variant="body2"
          whiteSpace="nowrap">
          {t('common:goToPage')}
        </Typography>
        <Controller
          name="page"
          key={'page'}
          control={control}
          render={({
            field: { onChange, value, onBlur, name },
            fieldState: { error },
          }) => (
            <InputField
              sx={{
                width: 70,
              }}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e);
                debouncedSubmit();
              }}
              required
              error={!!error}
              type="number"
              min={1}
              InputProps={{
                inputProps: {
                  'data-testid': 'go-to-page-input',
                  style: {
                    padding: '6px 12px',
                  },
                },
              }}
              max={totalPages}
            />
          )}
        />
      </Box>
      {errors?.page?.message ? (
        <Typography
          color="errorIndicator.dark"
          variant="caption"
          data-testid="go-to-page-input-error">
          {errors.page.message}
        </Typography>
      ) : null}
    </Box>
  );
};

export default GoToPageInput;
