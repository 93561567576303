import axios from 'axios';
import { attachAcceptLanguage } from 'src/api/interceptors/attach-accept-language';
import { attachAccessToken } from 'src/api/interceptors/attach-access-token';
import { attachDirectoryToken } from 'src/api/interceptors/attach-directory-token';

// TODO: move to shared, more features can be using this client
const tenantApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/tenant-admin/v1/tenants`,
});

tenantApi.interceptors.request.use(attachAcceptLanguage);
tenantApi.interceptors.request.use(attachAccessToken);
tenantApi.interceptors.request.use(attachDirectoryToken);

export default tenantApi;
