import type { FC, ReactNode } from 'react';

import { Error, Warning } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog as DialogCore,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Info } from 'src/icons/info';
import { LoadingButton } from '../atoms/loading-button/loading-button';

export interface DialogActions {
  open: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

interface DialogProps extends DialogActions {
  children: ReactNode;
  title: string;
  variant?: 'error' | 'warning' | 'info';
  confirmButtonText?: string;
  cancelButtonText?: string;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonSubmitting?: boolean;
  confirmButtonId?: string;
  confirmButtonTooltip?: string;
  cancelButtonId?: string;
  pagination?: ReactNode;
  additionalActionSlot?: ReactNode;
  scrollable?: boolean;
  maxWidth?: string;
}

const icons = {
  error: <Error color="error" fontSize="large" />,
  warning: <Warning color="warning" fontSize="large" />,
  info: <Info color="info" fontSize="large" />,
};

export const Dialog: FC<DialogProps> = ({
  children,
  onCancel,
  onConfirm,
  open,
  title,
  variant,
  confirmButtonText,
  cancelButtonText,
  isConfirmButtonDisabled = false,
  confirmButtonId,
  confirmButtonTooltip = '',
  cancelButtonId,
  pagination,
  isConfirmButtonSubmitting,
  additionalActionSlot,
  scrollable,
  maxWidth,
}) => {
  const { t } = useTranslation();

  return (
    <DialogCore
      onClose={onCancel}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: maxWidth ?? undefined,
          },
        },
      }}
      PaperProps={{
        sx: {
          width: '100%',
          overflowY: 'unset',
        },
      }}>
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}>
        {variant ? icons[variant] : null}
        <Typography
          color="inherit"
          sx={{
            ml: variant ? 2 : 0,
            width: '100%',
            textAlign: 'left',
          }}
          variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ overflowY: scrollable ? 'auto' : 'unset' }}>
        {children}
      </DialogContent>
      {pagination}
      <DialogActions>
        {additionalActionSlot}
        {onCancel ? (
          <Button
            color="primary"
            onClick={onCancel}
            variant="text"
            id={cancelButtonId}>
            {cancelButtonText ?? t('common:button.cancel')}
          </Button>
        ) : null}
        {onConfirm ? (
          <Tooltip
            title={confirmButtonTooltip}
            disableFocusListener={!isConfirmButtonDisabled}
            disableHoverListener={!isConfirmButtonDisabled}>
            <span>
              <LoadingButton
                color="primary"
                onClick={onConfirm}
                variant="contained"
                id={confirmButtonId}
                disabled={isConfirmButtonDisabled}
                loading={isConfirmButtonSubmitting ?? false}>
                {confirmButtonText ?? t('common:button.save')}
              </LoadingButton>
            </span>
          </Tooltip>
        ) : null}
      </DialogActions>
    </DialogCore>
  );
};
