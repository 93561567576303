import dialogs from './dialogs.json';
import details from './details.json';
import editEvent from './edit-event.json';
import filters from './filters.json';
import events from './events.json';
import createEvent from './create-event.json';

export default {
  dialogs,
  details,
  editEvent,
  filters,
  events,
  createEvent,
};
