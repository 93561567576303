import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { editAdminPhoneNumber } from '../../api/lib/admins';
import { siteAdministratorsKeys } from '../../api/query-keys/administrators';
import { ApiError } from 'src/types/interfaces/api-error';
import { delayedCallbackExecution } from 'src/utils/delayed-callback-execution';

export const useEditAdminPhoneNumber = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      siteAdminToken,
      phoneNumber,
    }: {
      siteAdminToken: string;
      phoneNumber: string;
    }) => editAdminPhoneNumber(siteAdminToken, phoneNumber),
    onSuccess: () => {
      delayedCallbackExecution(1000, () => {
        queryClient.invalidateQueries({
          queryKey: siteAdministratorsKeys.lists(),
        });
      });

      onSuccess?.();

      toast.success(
        t('siteManagement:administrators.editAdminPhoneNumberDialog.success'),
      );
    },
    onError: (error: ApiError) => {
      toast.error(error?.response?.data?.message);
    },
  });
};
