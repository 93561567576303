import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePrompt } from 'src/hooks/use-prompt';
import { Box, FormHelperText } from '@mui/material';
import { InputField } from 'src/components/atoms/input-field';
import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import PhoneNumberInput from 'src/components/atoms/phone-number-input/phone-number-input';
import { useInviteTenant } from 'src/features/site-tenants/hooks/api/use-invite-tenant';

interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
}

const defaultValues: FormValues = {
  name: '',
  email: '',
  phoneNumber: '',
};

const TenantInviteDialog = ({ open, onCancel }: DialogActions) => {
  const { t } = useTranslation();

  const handleOnCancel = () => {
    onCancel?.();
    reset(defaultValues);
  };

  const { mutate: inviteTenant, isPending: inviteTenantIsLoading } =
    useInviteTenant(handleOnCancel);

  const validationSchema = z.object({
    name: z.string().min(1, t('validation:required')),
    email: z
      .string()
      .email(t('validation:email'))
      .min(1, t('validation:required')),
    phoneNumber: z
      .string()
      .optional()
      .refine(
        value => value === '' || (value && value.length >= 11),
        t('validation:phoneNumber'),
      ),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  usePrompt(t('common:areYouSureYouWantToLeaveThePage'), isDirty);

  const onSubmit = ({ email, phoneNumber, name }: FormValues) => {
    inviteTenant({
      tenantOwnerEmail: email,
      tenantOwnerPhoneNumber: phoneNumber ? `+${phoneNumber}` : '',
      tenantName: name,
    });
  };

  return (
    <Dialog
      open={open}
      title={t('siteTenants:tenants.inviteTenantDialog.title')}
      onCancel={handleOnCancel}
      onConfirm={handleSubmit(onSubmit)}
      isConfirmButtonSubmitting={inviteTenantIsLoading}
      isConfirmButtonDisabled={!isValid}
      confirmButtonId="invite_tenant_save_button"
      confirmButtonText={t('common:button.confirm')}>
      <Controller
        control={control}
        name="name"
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error, invalid },
        }) => (
          <InputField
            name="name"
            fullWidth
            type="text"
            label={t('siteTenants:tenants.inviteTenantDialog.nameInputLabel')}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            required
            id="invite_tenant_name_input"
            data-testid="invite_tenant_name_input"
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState }) => (
            <InputField
              fullWidth
              sx={{ my: 1 }}
              name="email"
              label={t(
                'siteTenants:tenants.inviteTenantDialog.emailInputLabel',
              )}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              required
              error={Boolean(fieldState.invalid)}
              helperText={fieldState.error?.message}
              id="invite_tenant_email_input"
              data-testid="invite_tenant_email_input"
            />
          )}
        />
        <Box sx={{ width: '100%' }}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState }) => (
              <PhoneNumberInput
                label={t(
                  'siteTenants:tenants.inviteTenantDialog.phoneNumberInputLabel',
                )}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={Boolean(fieldState.invalid && fieldState.isTouched)}
                helperText={fieldState.error?.message}
                name="phone"
              />
            )}
          />
        </Box>
      </Box>
      {errors?.root ? (
        <FormHelperText error>{errors?.root?.message}</FormHelperText>
      ) : null}
    </Dialog>
  );
};

export default TenantInviteDialog;
