import { InternalAxiosRequestConfig } from 'axios';
import { useUserStore } from 'src/store/user-store';

export const attachMeilisearchApiKey = (config: InternalAxiosRequestConfig) => {
  const meilisearchToken = useUserStore.getState().meilisearchToken;

  if (meilisearchToken) {
    config.headers['Authorization'] = `Bearer ${meilisearchToken}`;
  }

  return config;
};
