import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import type { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'src/components/atoms/scrollbar';
import { DataStatusIndicator } from 'src/components/molecules/data-status-indicator/data-status-indicator';
import { Tenant } from 'src/features/site-tenants/api/types/tenant';
import { TenantsActionSlotPayload } from 'src/features/site-tenants/types/payloads/tenants-action-slot-payload';
import TenantsTableRow from './tenants-table-row';

interface Props {
  data: Tenant[];
  isLoading: boolean;
  error: string | undefined;
  actionsSlot: (payload: TenantsActionSlotPayload) => ReactNode;
}

const TenantsTable = ({ isLoading, actionsSlot, data, error }: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      id: 'tenant',
      label: t('siteTenants:tenants.columns.tenant'),
    },
    //TODO: Add users column when api is ready
    // {
    //   id: 'users',
    //   label: t('siteTenants:tenants.columns.users'),
    // },
    {
      id: 'admin',
      label: t('siteTenants:tenants.columns.admin'),
    },
    {
      id: 'phoneNumber',
      label: t('siteTenants:tenants.columns.phoneNumber'),
    },
    {
      id: 'invitationStatus',
      label: t('siteTenants:tenants.columns.invitationStatus'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      <Scrollbar autoHide={false}>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ name, tenantToken, invitation }) => {
              return (
                <TenantsTableRow
                  key={name}
                  name={name}
                  email={invitation?.tenantOwnerEmail ?? 'N/A'}
                  phoneNumber={invitation?.tenantOwnerPhoneNumber ?? 'N/A'}
                  status={invitation?.status ?? 'PENDING'}
                  invitationToken={invitation?.tenantInvitationToken ?? 'N/A'}
                  createdAt={new Date(invitation?.createdAt ?? new Date())}
                  tenantToken={tenantToken}
                  actionsSlot={actionsSlot}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <DataStatusIndicator
        dataLength={data?.length}
        error={error}
        isFetching={isLoading}
      />
    </Box>
  );
};

export default TenantsTable;
