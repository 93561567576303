import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useTranslation } from 'react-i18next';
import { InputField } from 'src/components/atoms/input-field';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { z } from 'zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneNumberInput from 'src/components/atoms/phone-number-input/phone-number-input';
import { UserRole } from 'src/types/enum/user-role';
import { useInviteAdmin } from 'src/features/site-management/hooks/api/use-invite-admin';
import { usePrompt } from 'src/hooks/use-prompt';
import { usePermissions } from 'src/permissions/can';
import { subject } from '@casl/ability';

interface FormValues {
  email: string;
  phoneNumber: string;
  role: UserRole;
}

interface Props extends DialogActions {
  selectedAdminToken: string;
  selectedAdminRole: UserRole;
}

const defaultValues = {
  email: '',
  phoneNumber: '',
  role: UserRole.OWNER,
};

const InviteAdminDialog = ({
  open,
  onCancel,
  selectedAdminToken,
  selectedAdminRole,
}: Props) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const roleOptions = [
    {
      value: UserRole.OWNER,
      label: 'common:roles.owner',
      description:
        'siteManagement:administrators.inviteAdminDialog.ownerDescription',
      disabled: permissions.cannot(
        'create',
        subject('SiteAdministrators', {
          userToken: selectedAdminToken,
          role: selectedAdminRole,
          selectedRole: UserRole.OWNER,
        }),
      ),
    },
    {
      value: UserRole.ADMIN,
      label: 'common:roles.admin',
      description:
        'siteManagement:administrators.inviteAdminDialog.adminDescription',
      disabled: permissions.cannot(
        'create',
        subject('SiteAdministrators', {
          userToken: selectedAdminToken,
          role: selectedAdminRole,
          selectedRole: UserRole.ADMIN,
        }),
      ),
    },
    {
      value: UserRole.VIEWER,
      label: 'common:roles.viewer',
      description:
        'siteManagement:administrators.inviteAdminDialog.viewerDescription',
      disabled: permissions.cannot(
        'create',
        subject('SiteAdministrators', {
          userToken: selectedAdminToken,
          role: selectedAdminRole,
          selectedRole: UserRole.VIEWER,
        }),
      ),
    },
  ];

  const validationSchema = z
    .object({
      email: z
        .string()
        .email(t('validation:email'))
        .min(1, t('validation:required')),
      phoneNumber: z
        .string()
        .optional()
        .refine(
          value => value === '' || (value && value.length >= 9),
          t('validation:phoneNumber'),
        ),
      role: z.nativeEnum(UserRole),
    })
    .refine(value =>
      permissions.can(
        'create',
        subject('SiteAdministrators', {
          userToken: selectedAdminToken,
          role: selectedAdminRole,
          selectedRole: value.role,
        }),
      ),
    );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  usePrompt(t('common:areYouSureYouWantToLeaveThePage'), isDirty);

  const handleOnCancel = () => {
    onCancel?.();
    reset(defaultValues);
  };

  const { mutate: inviteAdmin, isPending: isLoadingInviteAdmin } =
    useInviteAdmin(handleOnCancel);

  const onSubmit: SubmitHandler<FormValues> = data => {
    inviteAdmin({
      ...data,
      phoneNumber: data.phoneNumber ? `+${data.phoneNumber}` : undefined,
    });
  };

  return (
    <Dialog
      open={open}
      title={t('siteManagement:administrators.inviteAdminDialog.title')}
      onCancel={handleOnCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText={t('common:button.invite')}
      cancelButtonText={t('common:button.cancel')}
      confirmButtonId="invite_administrator_confirm_button"
      isConfirmButtonSubmitting={isLoadingInviteAdmin}
      isConfirmButtonDisabled={!isValid}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState }) => (
            <InputField
              fullWidth
              sx={{ my: 1 }}
              name="email"
              label={t(
                'siteManagement:administrators.inviteAdminDialog.emailInput',
              )}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              required
              error={Boolean(fieldState.invalid)}
              helperText={fieldState.error?.message}
              id="invite_administrator_email_input"
            />
          )}
        />
        <Box sx={{ width: '100%' }}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState }) => (
              <PhoneNumberInput
                label={t(
                  'siteManagement:administrators.inviteAdminDialog.phoneNumberInput',
                )}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={Boolean(fieldState.invalid && fieldState.isTouched)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </Box>
      <Controller
        name="role"
        render={({ field: { onChange, value, onBlur } }) => (
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}
            defaultValue={UserRole.OWNER}
            onChange={onChange}
            value={value}
            onBlur={onBlur}>
            {roleOptions.map(option => (
              <Box key={option.value}>
                <FormControlLabel
                  label={t(option.label)}
                  control={<Radio value={option.value} />}
                  disabled={option.disabled}
                />
                <FormHelperText>{t(option.description)}</FormHelperText>
              </Box>
            ))}
          </RadioGroup>
        )}
        control={control}
      />
      {errors?.root ? (
        <FormHelperText error>{errors?.root?.message}</FormHelperText>
      ) : null}
    </Dialog>
  );
};

export default InviteAdminDialog;
