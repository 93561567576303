import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import type {
  FilledTextFieldProps,
  SxProps,
  InputProps,
  Theme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import type { Theme as SystemTheme } from '@mui/system';

type ExactTheme = Theme extends SystemTheme ? Theme : never;

interface InputFieldProps extends Omit<FilledTextFieldProps, 'variant'> {
  InputProps?: InputProps;
  sx?: SxProps<ExactTheme>; // Use ExactTheme instead of Theme
  min?: number;
  max?: number;
  labelSize?: 'small' | 'regular';
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const { InputProps, sx, max, min, labelSize = 'regular', ...other } = props;

    return (
      <TextField
        {...other}
        ref={ref}
        inputProps={{
          ...props.inputProps,
          min,
          max,
          sx: {
            fontSize: 14,
            ...props.inputProps?.sx,
            alignItems: 'center',
            display: 'flex',
            height: 'unset',
            lineHeight: 1.6,
            px: 1.5,
            py: 0.75,
            '&.MuiInputBase-inputAdornedStart': {
              pl: 0,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
            },
          },
        }}
        variant="filled"
        InputLabelProps={{
          shrink: true,
          sx: {
            color: 'text.primary',
            fontSize: labelSize === 'regular' ? 14 : 12,
            fontWeight: 500,
            mb: 0.5,
            position: 'relative',
            transform: 'none',
            whiteSpace: 'pre-wrap',
          },
        }}
        sx={{
          '& .MuiFilledInput-root': {
            backgroundColor: 'background.paper',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'neutral.300',
            borderRadius: 1,
            boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
            overflow: 'hidden',
            p: 0,
            transition: theme =>
              theme.transitions.create(['border-color', 'box-shadow']),
            '&:before': {
              borderBottom: 0,
            },
            '&:hover': {
              backgroundColor: 'background.paper',
            },
            '&.Mui-focused': {
              backgroundColor: 'background.paper',
              borderColor: 'action.focus',
              boxShadow: theme => `${theme.palette.action.focus} 0 0 0 0.2rem`,
            },
            '&.Mui-disabled': {
              backgroundColor: 'action.disabledBackground',
              boxShadow: 'none',
              borderColor: alpha('#D6DBE1', 0.5),
            },
            '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
              {
                mt: 0,
                ml: 1.5,
              },
          },
          ...sx,
        }}
        FormHelperTextProps={{
          sx: {
            ml: 0,
          },
        }}
        InputProps={{
          ...InputProps,
          disableUnderline: true,
        }}
      />
    );
  },
);
