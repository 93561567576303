import {
  Box,
  Button,
  Card,
  Divider,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'src/components/molecules/page-header';
import { Pagination } from 'src/components/organisms/pagination';
import { fetchTenants } from 'src/features/site-tenants/api/lib/tenants';
import { siteTenantsKeys } from 'src/features/site-tenants/api/query-keys/tenants';
import { TenantsActionSlotPayload } from 'src/features/site-tenants/types/payloads/tenants-action-slot-payload';
import { useMeilisearchWithUrlParams } from 'src/features/tenant/hooks/meilisearch/use-meilisearch-with-url-params';
import { useDialog } from 'src/hooks/use-dialog';
import { useUrlQuery } from 'src/hooks/use-url-query';
import { Plus } from 'src/icons/plus';
import { Can, withPermissions } from 'src/permissions/can';
import { useUserStore } from 'src/store/user-store';
import TenantsActionsTableMenu from '../../molecules/tenants-actions-table-menu';
import TenantInviteDialog from '../../organisms/tenants/dialogs/tenant-invite-dialog';
import TenantRemoveInvitationDialog from '../../organisms/tenants/dialogs/tenant-remove-invitation-dialog';
import TenantsSearchBar from '../../organisms/tenants/tenants-search-bar';
import TenantsTable from '../../organisms/tenants/tenants-table';

const Tenants = () => {
  const { t } = useTranslation();
  const urlQuery = useUrlQuery();
  const { directoryToken } = useUserStore();

  const page = urlQuery.get<number>('page') ?? 1;
  const perPage = urlQuery.get<number>('limit') ?? 10;
  const {
    results,
    handleChangeQuery,
    handleChangePage,
    isLoading,
    isFetching,
    handleChangeLimit,
    error,
  } = useMeilisearchWithUrlParams(
    fetchTenants,
    siteTenantsKeys.list(directoryToken ?? '', perPage, page),
  );
  const totalPages = Math.ceil((results?.estimatedTotalHits ?? 0) / perPage);

  const [selectedTenant, setSelectedTenant] =
    useState<TenantsActionSlotPayload | null>(null);

  // Invite tenant
  const [
    isOpenInviteTenantDialog,
    handleOpenInviteTenantDialog,
    handleCloseInviteTenantDialog,
  ] = useDialog();

  // TODO: Uncomment this when api is ready
  // Remove tenant
  // const [
  //   isOpenRemoveTenantDialog,
  //   handleOpenRemoveTenantDialog,
  //   handleCloseRemoveTenantDialog,
  // ] = useDialog();

  // Remove tenant's invitation
  const [
    isOpenRemoveTenantInvitationDialog,
    handleOpenRemoveTenantInvitationDialog,
    handleCloseRemoveTenantInvitationDialog,
  ] = useDialog();

  const actionsSlot = useCallback(
    (payload: TenantsActionSlotPayload) => (
      <TenantsActionsTableMenu
        onCancelInvitation={() => {
          setSelectedTenant(payload);
          handleOpenRemoveTenantInvitationDialog();
        }}
        invitationStatus={payload.status}
      />
    ),
    [],
  );

  return (
    <>
      <PageHeader
        title={t('siteTenants:tenants.title')}
        extras={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isLoading ? (
              <Typography variant="body2">
                {t('siteTenants:tenants.totalTenants', {
                  amount: results?.estimatedTotalHits,
                })}
              </Typography>
            ) : (
              <Skeleton height={42} width={200} />
            )}
            <Can I="create" a="SiteTenants" passThrough>
              {allowed => (
                <Tooltip
                  title={t('common:notSufficientPermissions')}
                  disableHoverListener={allowed}
                  disableFocusListener={allowed}>
                  <span>
                    <Button
                      sx={{ ml: 2 }}
                      onClick={handleOpenInviteTenantDialog}
                      color="primary"
                      disabled={!allowed}
                      startIcon={<Plus fontSize="small" />}
                      variant="contained"
                      id="add_tenant_button"
                      size="large">
                      {t('siteTenants:tenants.addTenantButton')}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Can>
          </Box>
        }
      />
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
        }}>
        <TenantsSearchBar
          handleChangeQuery={handleChangeQuery}
          query={urlQuery.get<string>('q') ?? ''}
        />
        <TenantsTable
          data={results?.hits ?? []}
          isLoading={isLoading}
          error={error?.response?.data?.message}
          actionsSlot={actionsSlot}
        />
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          isFetching={isFetching}
          changePage={handleChangePage}
          rowsCount={results?.estimatedTotalHits || 0}
          page={page}
          perPage={perPage}
          totalPages={totalPages}
          handlePerPageChange={newLimit => handleChangeLimit(newLimit)}
        />
      </Card>
      <TenantInviteDialog
        open={isOpenInviteTenantDialog}
        onCancel={handleCloseInviteTenantDialog}
      />
      <TenantRemoveInvitationDialog
        open={isOpenRemoveTenantInvitationDialog}
        onCancel={handleCloseRemoveTenantInvitationDialog}
        selectedTenant={selectedTenant}
      />
      {/* TODO: Uncomment this when api is ready */}
      {/* <TenantRemoveDialog
        open={isOpenRemoveTenantDialog}
        onCancel={handleCloseRemoveTenantDialog}
        selectedTenant={selectedTenant}
      /> */}
      {/* <TenantRemovalErrorDialog
        open={true}
        onCancel={() => {}}
        onConfirm={() => {}}
        applicationsNames={['Parkiza', 'Wayin']}
        tenantName={'tenantName'}
      /> */}
    </>
  );
};

export default withPermissions(
  permissions => permissions.cannot('read', 'SiteTenants'),
  Tenants,
);
