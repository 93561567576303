import { EmployeesIcon } from 'src/icons/navigation-icons/employees-icon';
import { EnvironmentalIndicatorsIcon } from 'src/icons/navigation-icons/environmental-indicators-icon';
import { EventsIcon } from 'src/icons/navigation-icons/events-icon';
import { FaqsIcon } from 'src/icons/navigation-icons/faqs-icon';
import { MessagesIcon } from 'src/icons/navigation-icons/messages-icon';
import { MobileAppManagementIcon } from 'src/icons/navigation-icons/mobile-app-management';
import { PasswordIcon } from 'src/icons/navigation-icons/password-icon';
import { PlacesIcon } from 'src/icons/navigation-icons/places-icon';
import { PollsIcon } from 'src/icons/navigation-icons/polls-icon';
import { PostsIcon } from 'src/icons/navigation-icons/posts-icon';
import { SiteDetailsIcon } from 'src/icons/navigation-icons/site-details-icon';
import { RouteTitle, SidebarRouteTitle } from 'src/types/enum/route-title';
import { RoutesGroup } from 'src/types/interfaces/routes-group';

export const getRoutes = (): RoutesGroup[] => [
  {
    title: SidebarRouteTitle.SETUP,
    routes: [
      {
        title: RouteTitle.TENANTS,
        icon: EmployeesIcon,
        href: '/tenants',
        id: 'tenants_navigation_link',
      },
      {
        title: RouteTitle.MOBILE_APP_MANAGEMENT,
        icon: MobileAppManagementIcon,
        href: '/mobile-app-management',
        id: 'mobile_app_management_navigation_link',
      },
      {
        title: RouteTitle.FAQS,
        icon: FaqsIcon,
        href: '/mobile-app-management/faqs',
        id: 'faqs_navigation_link',
      },
    ],
  },
  {
    title: SidebarRouteTitle.PUBLICATIONS,
    routes: [
      {
        title: RouteTitle.SURVEYS,
        icon: PollsIcon,
        href: '/publications/polls',
        id: 'publications_polls_navigation_link',
      },
      {
        title: RouteTitle.ARTICLES,
        icon: PostsIcon,
        href: '/publications/posts',
        id: 'publications_posts_navigation_link',
      },
      {
        title: RouteTitle.EVENTS,
        icon: EventsIcon,
        href: '/publications/events',
        id: 'publications_events_navigation_link',
      },
      {
        title: RouteTitle.MESSAGES,
        icon: MessagesIcon,
        href: '/publications/messages',
        id: 'publications_messages_navigation_link',
      },
      {
        title: RouteTitle.PLACES,
        icon: PlacesIcon,
        href: '/publications/places',
        id: 'publications_places_navigation_link',
      },
      {
        title: RouteTitle.ENVIRONMENTAL_INDICATORS,
        icon: EnvironmentalIndicatorsIcon,
        href: '/publications/environmental-indicators',
        id: 'publications_environmental_indicators_navigation_link',
      },
    ],
  },
  {
    title: SidebarRouteTitle.SUPPORT_AND_SETTINGS,
    routes: [
      {
        title: RouteTitle.SITE_MANAGEMENT,
        icon: SiteDetailsIcon,
        href: '/site-management',
        id: 'site_management_navigation_link',
      },
      {
        title: RouteTitle.PASSWORD,
        icon: PasswordIcon,
        href: '/password',
        id: 'password_navigation_link',
      },
      {
        icon: MessagesIcon,
        title: RouteTitle.AUDIT_LOGS,
        href: '/audit-logs',
        id: 'site-audit-logs_navigation_link',
      },
    ],
  },
];
