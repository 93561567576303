import { Box, Button, Typography, useTheme } from '@mui/material';
import type { ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { darkPrimary, lightPrimary } from 'src/colors';
import { RouteTitle } from 'src/types/enum/route-title';

interface SidebarItemProps {
  active: boolean;
  external?: boolean;
  href: string;
  icon: ElementType;
  pinned: boolean;
  title: RouteTitle;
  id?: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  active,
  external,
  href,
  icon: Icon,
  pinned,
  title,
  id,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <li>
      <Button
        component={RouterLink}
        fullWidth
        id={id}
        target={external ? '_target' : '_self'}
        startIcon={
          <Box
            sx={{
              minWidth: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Icon />
          </Box>
        }
        sx={{
          justifyContent: 'flex-start',
          minWidth: 'fit-content',
          fontSize: '13px',
          p: 1,
          pl: 3,
          '& .MuiButton-startIcon': {
            color: active
              ? mode === 'dark'
                ? darkPrimary.main
                : '#000'
              : 'text.secondary',
            margin: 0,
          },
          '& .MuiButton-endIcon': {
            color: 'action.disabled',
            display: pinned ? 'flex' : 'none',
            marginLeft: 'auto',
          },
          background: active
            ? `${
                mode === 'dark'
                  ? darkPrimary.hoverOutlined
                  : lightPrimary.hoverOutlined
              }14`
            : 'transparent',
          ':focus': {
            boxShadow: 'none',
            background: {
              md: active
                ? `${
                    mode === 'dark'
                      ? darkPrimary.hoverOutlined
                      : lightPrimary.hoverOutlined
                  }14`
                : 'transparent',
            },
          },
        }}
        to={href}
        variant="text">
        <Typography
          color="textPrimary"
          sx={{
            color: active ? 'text.primary' : 'text.secondary',
            display: pinned ? 'flex' : 'none',
            ml: 1.62,
          }}
          variant="body2">
          {t(`${title}` as const)}
        </Typography>
      </Button>
    </li>
  );
};
