import { AbilityBuilder } from '@casl/ability';
import { AppAbility, Role } from './define-permissions';

export const tenantContextPermissions = (
  builder: AbilityBuilder<AppAbility>,
  role: Role,
  userToken: string,
) => {
  const { can } = builder;

  can('read', 'OrganizationDetails');

  can('read', 'TenantAdministrators');

  can('read', 'TenantEmployees');

  can('read', 'TenantEmployeesJoinRequest');

  can('read', 'TenantEmployeesAccess');

  can('read', 'TenantEmployeesDisplayedApplications');

  can('read', 'TenantApplications');

  can('read', 'TenantDomains');

  can('read', 'TenantEmployeeJoinMethod');

  can('read', 'Password');
  can('update', 'Password');

  can('read', 'TenantContactForm');
  can('create', 'TenantContactForm');

  can('read', 'TenantEvents');

  can('read', 'TenantPolls');

  can('read', 'TenantPosts');

  can('read', 'TenantSiteDetails');

  can('read', 'TenantReceptionDetails');

  can('read', 'TenantMessages');

  can('read', 'TenantAuditLogs');

  if (role === 'admin' || role === 'owner' || role === 'master') {
    can('create', 'TenantEmployees');
    can('upload', 'TenantEmployees');
    can('update', 'TenantEmployees');
    can('delete', 'TenantEmployees');
    can('resendInvitation', 'TenantEmployees');

    can('approve', 'TenantEmployeesJoinRequest');
    can('reject', 'TenantEmployeesJoinRequest');

    can('stopDisplaying', 'TenantEmployeesDisplayedApplications');

    can('grant', 'TenantEmployeesAccess');
    can('update', 'TenantEmployeesAccess');
    can('revoke', 'TenantEmployeesAccess');

    can('setAsDefault', 'TenantApplications');
    can('removeFromDefault', 'TenantApplications');

    can('create', 'TenantDomains');
    can('delete', 'TenantDomains');

    can('update', 'TenantEmployeeJoinMethod');

    can('create', 'TenantEvents');
    can('update', 'TenantEvents');
    can('delete', 'TenantEvents');

    can('create', 'TenantPolls');
    can('update', 'TenantPolls');
    can('delete', 'TenantPolls');

    can('create', 'TenantPosts');
    can('update', 'TenantPosts');
    can('delete', 'TenantPosts');
    can('publish', 'TenantPosts');
    can('unpublish', 'TenantPosts');

    can('create', 'TenantReceptionDetails');
    can('update', 'TenantReceptionDetails');
    can('delete', 'TenantReceptionDetails');

    can('create', 'TenantMessages');
    can('update', 'TenantMessages');
    can('delete', 'TenantMessages');
    can('publish', 'TenantMessages');
    can('duplicate', 'TenantMessages');
  }

  if (role === 'owner' || role === 'master') {
    can('create', 'TenantAdministrators');
    can('updatePhoneNumber', 'TenantAdministrators');
    can('updateRole', 'TenantAdministrators', {
      userToken: { $ne: userToken },
    });
    can('delete', 'TenantAdministrators', {
      userToken: { $ne: userToken },
    });
    can('resendInvitation', 'TenantAdministrators', {
      userToken: { $ne: userToken },
    });
  }

  if (role === 'admin') {
    can('create', 'TenantAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('updatePhoneNumber', 'TenantAdministrators', {
      role: { $ne: 'OWNER' },
    });
    can('updateRole', 'TenantAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('delete', 'TenantAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
      selectedRole: { $nin: ['OWNER', 'ADMIN'] },
    });
    can('resendInvitation', 'TenantAdministrators', {
      userToken: { $ne: userToken },
      role: { $nin: ['OWNER', 'ADMIN'] },
    });
  }
};
