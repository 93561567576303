import { TableCell, TableRow, Typography } from '@mui/material';
import { Status } from 'src/components/atoms/status';
import {
  adminsRoleTranslations,
  adminsRolesColor,
} from 'src/constants/admins-roles';
import {
  adminInvitationStateColor,
  adminInvitationStateTranslations,
} from '../../../constants/admin-invitation-status';
import { AdminsActionSlotPayload } from 'src/features/site-management/types/payloads/admins-action-slot-payload';
import { ReactNode } from 'react';
import { UserRole } from 'src/types/enum/user-role';
import { AdminInvitationStatus } from 'src/features/site-management/types/enum/admin-invitation-status';
import { useTranslation } from 'react-i18next';

interface Props {
  token: string;
  email: string;
  phoneNumber: string;
  role: UserRole;
  status: AdminInvitationStatus;
  actionsSlot: (payload: AdminsActionSlotPayload) => ReactNode;
}

export default function AdminsTableRow({
  token,
  email,
  phoneNumber,
  role,
  status,
  actionsSlot,
}: Props) {
  const { t } = useTranslation();

  return (
    <TableRow key={token}>
      <TableCell>
        <Typography color="text.primary" variant="inherit">
          {email}
        </Typography>
      </TableCell>
      <TableCell>
        {phoneNumber ? (
          <Typography variant="inherit">{phoneNumber}</Typography>
        ) : (
          <Typography color="textSecondary" variant="inherit">
            N/A
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Status
          label={t(adminsRoleTranslations[role])}
          color={adminsRolesColor[role]}
        />
      </TableCell>
      <TableCell>
        <Status
          label={t(
            adminInvitationStateTranslations[
              status ?? AdminInvitationStatus.PENDING
            ],
          )}
          color={
            adminInvitationStateColor[status ?? AdminInvitationStatus.PENDING]
          }
        />
      </TableCell>
      <TableCell align="right">
        {actionsSlot({
          token,
          email,
          phoneNumber,
          role,
          status,
        })}
      </TableCell>
    </TableRow>
  );
}
