import type { FC } from "react";
import { Button, SxProps, Typography, styled } from "@mui/material";
import { Plus as PlusIcon } from "../../icons/plus";
import { QuestionMarkOutlined as QuestionMarkIcon } from "../../icons/question-mark-outlined";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/system";

interface ResourceUnavailableProps {
  onCreate?: () => void;
  sx?: SxProps;
  text?: string;
}

const ResourceUnavailableRoot = styled("div")(({ theme }: { theme: Theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.neutral[100],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
}));

export const ResourceUnavailable: FC<ResourceUnavailableProps> = (props) => {
  const { onCreate, text, ...other } = props;
  const { t } = useTranslation();

  return (
    <ResourceUnavailableRoot {...other} data-testid="resource-unavailable-wrapper">
      <QuestionMarkIcon sx={{ color: "text.secondary" }} />
      <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
        {text ? text : t("common:noResults")}
      </Typography>
      {onCreate && (
        <Button color="primary" onClick={onCreate} startIcon={<PlusIcon fontSize="small" />} sx={{ mt: 2 }} variant="contained">
          Create Object
        </Button>
      )}
    </ResourceUnavailableRoot>
  );
};
