import posts from './posts.json';
import details from './details.json';
import createPost from './create-post.json';
import editPost from './edit-post.json';
import postSteps from './post-steps.json';

export default {
  posts,
  details,
  createPost,
  editPost,
  postSteps,
};
