import type { FC } from "react";

import { Box, Typography } from "@mui/material";
import { StatusBadge } from "./status-badge";

interface StatusProps {
  color: string;
  label: string;
}

export const Status: FC<StatusProps> = (props) => {
  const { color, label, ...other } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
      }}
      {...other}
    >
      <StatusBadge color={color} />
      <Typography
        sx={{
          color,
          ml: 1.75,
        }}
        variant="body2"
      >
        {label}
      </Typography>
    </Box>
  );
};
