export const dateFormats = {
  day: "dd",
  dayShort: "d",
  monthShort: "MMM",
  month: "MM",
  monthLong: "MMMM",
  year: "yyyy",
  yearShort: "yy",
  hours: "HH",
  minutes: "mm",
  weekDay: "EEE",
  seconds: "SS",
};
