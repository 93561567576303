export const InvitationSettingsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08337 4.16667H5.41671M5.41671 4.16667V3.33333C5.41671 2.8731 5.7898 2.5 6.25004 2.5H9.16671C9.62694 2.5 10 2.8731 10 3.33333V4.16667M5.41671 4.16667V5C5.41671 5.46024 5.7898 5.83333 6.25004 5.83333H9.16671C9.62694 5.83333 10 5.46024 10 5V4.16667M10 4.16667H17.9167"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M2.5 15.8332H5.83333M5.83333 15.8332V14.9998C5.83333 14.5396 6.20643 14.1665 6.66667 14.1665H9.58333C10.0436 14.1665 10.4167 14.5396 10.4167 14.9998V15.8332M5.83333 15.8332V16.6665C5.83333 17.1267 6.20643 17.4998 6.66667 17.4998H9.58333C10.0436 17.4998 10.4167 17.1267 10.4167 16.6665V15.8332M10.4167 15.8332H18.3333"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M18.3334 10.0002H15M15 10.0002V9.16683C15 8.70659 14.6269 8.3335 14.1667 8.3335H11.25C10.7898 8.3335 10.4167 8.70659 10.4167 9.16683V10.0002M15 10.0002V10.8335C15 11.2937 14.6269 11.6668 14.1667 11.6668H11.25C10.7898 11.6668 10.4167 11.2937 10.4167 10.8335V10.0002M10.4167 10.0002H2.50004"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
