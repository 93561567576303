import React, { ReactNode } from 'react';
import { Typography, Box } from '@mui/material';

interface PageHeaderProps {
  title: string;
  titleAdditionalSlot?: ReactNode;
  extras?: ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  titleAdditionalSlot,
  extras,
}) => {
  return (
    <Box sx={{ py: 4 }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}>
        {title ? (
          <>
            <Typography color="textPrimary" variant="h4">
              {title}
            </Typography>
            {titleAdditionalSlot}
            <Box sx={{ flexGrow: 1 }} />
          </>
        ) : null}
        {extras}
      </Box>
    </Box>
  );
};
