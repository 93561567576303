import general from './general.json';
import permissionsTable from './permissions-table.json';
import revokeAllPermissionsDialog from './revoke-all-permissions-dialog.json';
import grantAllPermissionsDialog from './grant-all-permissions-dialog.json';
import managePermissionsDialog from './manage-permissions-dialog.json';

export default {
  general,
  permissionsTable,
  revokeAllPermissionsDialog,
  grantAllPermissionsDialog,
  managePermissionsDialog,
};
