import { QueryKey } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiError } from 'src/types/interfaces/api-error';
import { MeiliSearchResponse } from 'src/types/responses/meilisearch';
import { SearchState } from '../../types/interfaces/search-state';
import { FilterValue } from './types/filter-value';
import { MeiliSearchSearchState } from './types/meilisearch-search-state';
import { useMeilisearch } from './use-meilisearch';
import { buildFiltersFromUrlParamsHandlers } from './utils/build-filters-from-url-params-handlers';
import { buildSearchStateFilters } from './utils/build-search-state-filters';
import { searchStateToUrl } from './utils/search-state-to-url';
import { urlToSearchState } from './utils/url-to-search-state';

export interface useMeilisearchReturn<T> {
  results: MeiliSearchResponse<T> | undefined;
  handleChangeFilterValue: (facetType: string, val: FilterValue) => void;
  buildFiltersFromUrlParams: (params: string[][]) => {
    [key: string]: string[];
  };
  handleChangeQuery: (value: string) => void;
  handleChangePage: (newPage: number) => void;
  handleChangeSort: (newSort: string[]) => void;
  resetFilters: () => void;
  isLoading: boolean;
  error: ApiError | null;
  handleChangeLimit: (newLimit: number) => void;
  isFetching: boolean;
}

// TODO: build function that will convert searchstate to meili filter format with options like AND and OR
export function useMeilisearchWithUrlParams<
  TData,
  TQueryKey extends QueryKey = QueryKey,
>(
  fetch: (
    directoryToken: string,
    searchState: SearchState,
    page: number,
    perPage: number,
  ) => Promise<MeiliSearchResponse<TData>>,
  queryKey?: TQueryKey,
  defaultSearchStateConfig?: Partial<MeiliSearchSearchState>,
  defaultSort?: string[],
): useMeilisearchReturn<TData> {
  const navigate = useNavigate();
  const location = useLocation();

  const searchState: SearchState = {
    q: urlToSearchState(location).q,
    filter: urlToSearchState(location).filter,
    sort: urlToSearchState(location).sort,
  };

  const getValueFromUrl = useCallback(
    <T,>(value: keyof MeiliSearchSearchState): T => {
      // @ts-expect-error TODO:We need to find better way of typing
      return urlToSearchState(location)[value];
    },
    [location],
  );

  const { results, error, isFetching, isLoading } = useMeilisearch<TData>({
    fetch,
    page: getValueFromUrl('page') ?? 1,
    perPage: getValueFromUrl('limit') ?? 10,
    queryKey: [...(queryKey ?? ''), location.search],
    searchState,
  });

  const buildFiltersFromUrlParams = (params: string[][]) => {
    const filtersObject: { [key: string]: string[] } = {};

    const stringCasesArray = [' = ', ' TO '];

    params.flat(1).map(param => {
      stringCasesArray.find(stringCase => {
        if (param.includes(stringCase)) {
          const buildFiltersBasedOnParams =
            buildFiltersFromUrlParamsHandlers[stringCase];

          const filter = buildFiltersBasedOnParams(param);

          if (Object.prototype.hasOwnProperty.call(filtersObject, filter.key)) {
            if (Array.isArray(filtersObject[filter.key])) {
              filtersObject[filter.key] = [
                ...(filtersObject[filter.key] as string[]),
                filter.value as string,
              ];
            }
          } else {
            filtersObject[filter.key] = [filter.value as string];
          }
        }
      });
    });

    return filtersObject;
  };

  const handleChangeFilterValue = useCallback(
    (facetType: string, filterValue: FilterValue) => {
      const filtersObject = buildFiltersFromUrlParams(
        getValueFromUrl('filter') ?? [],
      );

      const facetTypeFilters = filtersObject[facetType];

      if (Array.isArray(filterValue)) {
        const hasEvery = (facetTypeFilters as string[])
          ?.flat(1)
          .every(filter =>
            filterValue.some(filterValue => filter === filterValue),
          );

        if (hasEvery) {
          delete filtersObject[facetType];
        } else {
          filtersObject[facetType] = filterValue as string[];
        }
      } else {
        if (facetTypeFilters) {
          if (facetTypeFilters.indexOf(filterValue as string) > -1) {
            facetTypeFilters.splice(
              facetTypeFilters.indexOf(filterValue as string),
              1,
            );
          } else {
            (facetTypeFilters as string[]).push(filterValue as string);
          }
        } else {
          filtersObject[facetType] = [filterValue as string];
        }
      }

      navigate(
        searchStateToUrl(location, {
          ...defaultSearchStateConfig,
          sort: getValueFromUrl<string[]>('sort') ?? [],
          q: getValueFromUrl<string>('q') ?? '',
          filter: buildSearchStateFilters(
            filtersObject as { [key: string]: string[] },
          ),
          page: 1,
          limit: getValueFromUrl<number>('limit') ?? 10,
        }),
        { ...location, replace: true },
      );
    },
    [location.search],
  );

  const resetFilters = useCallback(() => {
    navigate(
      searchStateToUrl(location, {
        ...defaultSearchStateConfig,
        sort: getValueFromUrl<string[]>('sort') ?? [],
        q: getValueFromUrl<string>('q') ?? '',
        filter: [[]],
        page: 1,
        limit: getValueFromUrl<number>('limit') ?? 10,
      }),
      { ...location, replace: true },
    );
  }, []);

  useEffect(() => {
    navigate(
      searchStateToUrl(location, {
        ...defaultSearchStateConfig,
        filter:
          getValueFromUrl<string[][]>('filter') ??
          defaultSearchStateConfig?.filter,
        sort:
          getValueFromUrl<string[]>('sort') ??
          defaultSearchStateConfig?.sort ??
          defaultSort,
        q: getValueFromUrl<string>('q') ?? defaultSearchStateConfig?.q,
        page: getValueFromUrl<number>('page') ?? defaultSearchStateConfig?.page,
        limit:
          getValueFromUrl<number>('limit') ?? defaultSearchStateConfig?.limit,
      }),
      { ...location, replace: true },
    );
  }, [location.search]);

  const handleChangeQuery = useCallback(
    (value: string) => {
      navigate(
        searchStateToUrl(location, {
          ...defaultSearchStateConfig,
          sort: getValueFromUrl<string[]>('sort') ?? [],
          q: value.toString(),
          filter: getValueFromUrl<string[][]>('filter') ?? [],
          page: 1,
          limit: getValueFromUrl<number>('limit') ?? 10,
        }),
        { ...location, replace: true },
      );
    },
    [defaultSearchStateConfig, getValueFromUrl, location, navigate],
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      navigate(
        searchStateToUrl(location, {
          ...defaultSearchStateConfig,
          sort: getValueFromUrl<string[]>('sort') ?? [],
          q: getValueFromUrl<string>('q') ?? '',
          filter: getValueFromUrl<string[][]>('filter') ?? [],
          page: newPage,
          limit: getValueFromUrl<number>('limit') ?? 10,
        }),
        { ...location, replace: true },
      );
    },
    [defaultSearchStateConfig, getValueFromUrl, location, navigate],
  );

  const handleChangeSort = useCallback(
    (newSort: string[]) => {
      navigate(
        searchStateToUrl(location, {
          ...defaultSearchStateConfig,
          sort: newSort,
          q: getValueFromUrl<string>('q') ?? '',
          filter: getValueFromUrl<string[][]>('filter') ?? [],
          page: getValueFromUrl<number>('page') ?? 1,
          limit: getValueFromUrl<number>('limit') ?? 10,
        }),
        { ...location, replace: true },
      );
    },
    [defaultSearchStateConfig, getValueFromUrl, location, navigate],
  );

  const handleChangeLimit = useCallback(
    (newLimit: number) => {
      navigate(
        searchStateToUrl(location, {
          ...defaultSearchStateConfig,
          sort: getValueFromUrl<string[]>('sort') ?? [],
          q: getValueFromUrl<string>('q') ?? '',
          filter: getValueFromUrl<string[][]>('filter') ?? [],
          page: 1,
          limit: newLimit,
        }),
        { ...location, replace: true },
      );
    },
    [defaultSearchStateConfig, getValueFromUrl, location, navigate],
  );

  return {
    results,
    handleChangeFilterValue,
    buildFiltersFromUrlParams,
    handleChangeQuery,
    handleChangePage,
    handleChangeSort,
    resetFilters,
    isLoading,
    error,
    isFetching,
    handleChangeLimit,
  };
}
