import { Box, Button, Card, Divider, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'src/components/molecules/page-header';
import { Pagination } from 'src/components/organisms/pagination';
import { fetchAdmins } from 'src/features/site-management/api/lib/admins';
import { siteAdministratorsKeys } from 'src/features/site-management/api/query-keys/administrators';
import { AdminsActionSlotPayload } from 'src/features/site-management/types/payloads/admins-action-slot-payload';
import { useMeilisearchWithUrlParams } from 'src/features/tenant/hooks/meilisearch/use-meilisearch-with-url-params';
import { useDialog } from 'src/hooks/use-dialog';
import { useUrlQuery } from 'src/hooks/use-url-query';
import { Plus } from 'src/icons/plus';
import { Can, withPermissions } from 'src/permissions/can';
import { useUserStore } from 'src/store/user-store';
import { UserRole } from 'src/types/enum/user-role';
import AdminsActionsTableMenu from '../../molecules/administrators/admins-actions-table-menu';
import AdminsTable from '../../organisms/administrators/admins-table';
import EditAdminPhoneNumberDialog from '../../organisms/administrators/dialogs/edit-admin-phone-number-dialog';
import EditAdminRoleDialog from '../../organisms/administrators/dialogs/edit-admin-role-dialog';
import InviteAdminDialog from '../../organisms/administrators/dialogs/invite-admin-dialog';
import RemoveAdminDialog from '../../organisms/administrators/dialogs/remove-admin-dialog';
import ResendAdminInvitationDialog from '../../organisms/administrators/dialogs/resend-admin-invitation-dialog';

const Administrators = () => {
  const { t } = useTranslation();
  const { adminToken, directoryToken } = useUserStore();
  const urlQuery = useUrlQuery();

  const page = urlQuery.get<number>('page') ?? 1;
  const perPage = urlQuery.get<number>('limit') ?? 10;

  const {
    results,
    isLoading,
    error,
    isFetching,
    handleChangePage,
    handleChangeLimit,
  } = useMeilisearchWithUrlParams(
    fetchAdmins,
    siteAdministratorsKeys.list(directoryToken ?? '', perPage, page),
  );
  const totalPages = Math.ceil((results?.estimatedTotalHits ?? 0) / perPage);

  //Inviting admin
  const [
    isOpenInviteAdminDialog,
    handleOpenInviteAdminDialog,
    handleCloseInviteAdminDialog,
  ] = useDialog();

  //Editing admin phone number
  const [
    isOpenEditAdminPhoneNumberDialog,
    handleOpenEditAdminPhoneNumberDialog,
    handleCloseEditAdminPhoneNumberDialog,
  ] = useDialog();

  //Editing admin role
  const [
    isOpenEditAdminRoleDialog,
    handleOpenEditAdminRoleDialog,
    handleCloseEditAdminRoleDialog,
  ] = useDialog();

  //Resending admin invitation
  const [
    isOpenResendAdminInvitationDialog,
    handleOpenResendAdminInvitationDialog,
    handleCloseResendAdminInvitationDialog,
  ] = useDialog();

  //Removing admin
  const [
    isOpenRemoveAdminDialog,
    handleOpenRemoveAdminDialog,
    handleCloseRemoveAdminDialog,
  ] = useDialog();

  const [selectedAdmin, setSelectedAdmin] =
    useState<AdminsActionSlotPayload | null>(null);

  const actionsSlot = useCallback(
    ({ token, email, phoneNumber, role, status }: AdminsActionSlotPayload) => (
      <AdminsActionsTableMenu
        status={status}
        adminToken={token}
        adminRole={role}
        isCurrentAdminSelfIdentityEqual={adminToken === token}
        onEditPhoneNumberActionClick={() => {
          setSelectedAdmin({ token, email, phoneNumber, role, status });
          handleOpenEditAdminPhoneNumberDialog();
        }}
        onEditRoleActionClick={() => {
          setSelectedAdmin({ token, email, phoneNumber, role, status });
          handleOpenEditAdminRoleDialog();
        }}
        onRemoveAdminActionClick={() => {
          setSelectedAdmin({ token, email, phoneNumber, role, status });
          handleOpenRemoveAdminDialog();
        }}
        onResendInvitationActionClick={() => {
          setSelectedAdmin({ token, email, phoneNumber, role, status });
          handleOpenResendAdminInvitationDialog();
        }}
      />
    ),
    [],
  );

  return (
    <>
      <PageHeader
        title={t('siteManagement:administrators.title')}
        extras={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Can I="create" a="SiteAdministrators" passThrough>
              {allowed => (
                <Tooltip
                  title={t('common:notSufficientPermissions')}
                  disableHoverListener={allowed}
                  disableFocusListener={allowed}>
                  <span>
                    <Button
                      onClick={handleOpenInviteAdminDialog}
                      color="primary"
                      disabled={!allowed}
                      startIcon={<Plus fontSize="small" />}
                      variant="contained"
                      id="invite_admin_button"
                      size="large">
                      {t('siteManagement:administrators.inviteButton')}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Can>
          </Box>
        }
      />
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
        }}>
        <AdminsTable
          data={results?.hits ?? []}
          isFetching={isLoading}
          error={error?.response?.data?.message ?? ''}
          actionsSlot={actionsSlot}
        />
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          isFetching={isFetching}
          changePage={handleChangePage}
          rowsCount={results?.estimatedTotalHits || 0}
          page={urlQuery.get('page') ?? 1}
          perPage={urlQuery.get('limit') ?? 10}
          totalPages={totalPages}
          handlePerPageChange={newLimit => handleChangeLimit(newLimit)}
        />
      </Card>
      <InviteAdminDialog
        open={isOpenInviteAdminDialog}
        onCancel={handleCloseInviteAdminDialog}
        selectedAdminToken={selectedAdmin?.token ?? ''}
        selectedAdminRole={selectedAdmin?.role ?? UserRole.VIEWER}
      />
      <EditAdminPhoneNumberDialog
        open={isOpenEditAdminPhoneNumberDialog}
        onCancel={handleCloseEditAdminPhoneNumberDialog}
        selectedAdminToken={selectedAdmin?.token ?? ''}
        selectedAdminPhoneNumber={selectedAdmin?.phoneNumber ?? ''}
      />
      <EditAdminRoleDialog
        open={isOpenEditAdminRoleDialog}
        onCancel={handleCloseEditAdminRoleDialog}
        selectedAdminToken={selectedAdmin?.token ?? ''}
        selectedAdminRole={selectedAdmin?.role ?? UserRole.VIEWER}
      />
      <ResendAdminInvitationDialog
        open={isOpenResendAdminInvitationDialog}
        onCancel={handleCloseResendAdminInvitationDialog}
        selectedAdminToken={selectedAdmin?.token ?? ''}
        selectedAdminEmail={selectedAdmin?.email ?? ''}
      />
      <RemoveAdminDialog
        open={isOpenRemoveAdminDialog}
        onCancel={handleCloseRemoveAdminDialog}
        selectedAdminToken={selectedAdmin?.token ?? ''}
      />
    </>
  );
};

export default withPermissions(
  permissions => permissions.cannot('read', 'SiteAdministrators'),
  Administrators,
);
