import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useRemoveAdmin } from 'src/features/site-management/hooks/api/use-remove-admin';

interface Props extends DialogActions {
  selectedAdminToken: string;
}

const RemoveAdminDialog = ({ open, onCancel, selectedAdminToken }: Props) => {
  const { t } = useTranslation();

  const { mutate: removeAdmin, isPending: isLoadingRemoveAdmin } =
    useRemoveAdmin(onCancel);

  const onSubmit = () => {
    removeAdmin(selectedAdminToken);
  };

  return (
    <Dialog
      open={open}
      title={t('siteManagement:administrators.removeAdminDialog.title')}
      cancelButtonText={t('common:button.no')}
      onCancel={onCancel}
      onConfirm={onSubmit}
      variant="warning"
      isConfirmButtonSubmitting={isLoadingRemoveAdmin}
      confirmButtonId="remove_admin_confirm_button"
      confirmButtonText={t(
        'siteManagement:administrators.removeAdminDialog.yesRemove',
      )}>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t('siteManagement:administrators.removeAdminDialog.content')}
      </Typography>
    </Dialog>
  );
};

export default RemoveAdminDialog;
