import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TenantsActionSlotPayload } from 'src/features/site-tenants/types/payloads/tenants-action-slot-payload';
import { Dialog, DialogActions } from 'src/components/molecules/dialog';
import { useRemoveInvitation } from 'src/features/site-tenants/hooks/api/use-remove-invitation';

interface Props extends DialogActions {
  selectedTenant: TenantsActionSlotPayload | null;
}

const TenantRemoveInvitationDialog = ({
  open,
  onCancel,
  selectedTenant,
}: Props) => {
  const { t } = useTranslation();

  const { mutate: removeInvitation, isPending: removeInvitationIsLoading } =
    useRemoveInvitation(onCancel);

  const onSubmit = () => {
    if (!selectedTenant) return;
    removeInvitation(selectedTenant.invitationToken);
  };

  return (
    <Dialog
      open={open}
      title={t('common:areYouSure')}
      cancelButtonText={t('common:button.no')}
      onCancel={onCancel}
      onConfirm={onSubmit}
      variant="warning"
      isConfirmButtonSubmitting={removeInvitationIsLoading}
      isConfirmButtonDisabled={!selectedTenant?.email}
      confirmButtonId="remove_tenant_confirm_button"
      confirmButtonText={t(
        'siteTenants:tenants.removeInvitationDialog.yesRemove',
      )}>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t('siteTenants:tenants.removeInvitationDialog.content', {
          email: selectedTenant?.email,
        })}
      </Typography>
    </Dialog>
  );
};

export default TenantRemoveInvitationDialog;
