import gdpr from './gdpr.json';
import general from './general.json';
import receptionDetails from './reception-details.json';
import siteDetails from './site-details.json';
import siteProfile from './site-profile.json';

export default {
  general,
  siteProfile,
  siteDetails,
  receptionDetails,
  gdpr,
};
