import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tenantKeys } from 'src/api/query-keys/tenant/tenant';
import { confirmTenantJoin } from 'src/features/tenant/api/lib/tenant';

export const useConfirmTenantJoin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => confirmTenantJoin(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.all });
    },
  });
};
