import { useQuery } from '@tanstack/react-query';
import { tenantKeys } from 'src/api/query-keys/tenant/tenant';
import { fetchAvailableTenants } from 'src/features/tenant/api/lib/tenant';
import { ApiError } from 'src/types/interfaces/api-error';
import { AvailableTenants } from 'src/types/responses/available-tenants';

export const useAvailableTenants = (enabled?: boolean) => {
  return useQuery<AvailableTenants, ApiError>({
    queryFn: fetchAvailableTenants,
    queryKey: tenantKeys.all,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
