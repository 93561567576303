export const PostsIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 1.16699H1.33337M14.6667 12.8337H1.33337M5.77782 4.99512H1.33337M5.77782 9.00553H1.33337M9.92597 9.91699H13.4815C13.8964 9.91699 14.1038 9.91699 14.2623 9.83752C14.4016 9.76761 14.515 9.65606 14.586 9.51886C14.6667 9.36288 14.6667 9.1587 14.6667 8.75033V5.25033C14.6667 4.84195 14.6667 4.63777 14.586 4.48179C14.515 4.34459 14.4016 4.23304 14.2623 4.16313C14.1038 4.08366 13.8964 4.08366 13.4815 4.08366H9.92597C9.51111 4.08366 9.30369 4.08366 9.14523 4.16313C9.00585 4.23304 8.89253 4.34459 8.82152 4.48179C8.74078 4.63777 8.74078 4.84195 8.74078 5.25033V8.75033C8.74078 9.1587 8.74078 9.36288 8.82152 9.51886C8.89253 9.65606 9.00585 9.76761 9.14523 9.83752C9.30369 9.91699 9.51111 9.91699 9.92597 9.91699Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
