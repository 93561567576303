import { AxiosResponse } from 'axios';
import { generateSiteSearchKey } from 'src/api/lib/meilisearch';
import { useUserStore } from 'src/store/user-store';
import { generateTenantSearchKey } from '../../features/tenant/api/lib/meilisearch';

export const refreshMeilisearchApiKey = async (failedRequest: {
  response: AxiosResponse;
}) => {
  const viewContext = useUserStore.getState().viewContext;

  let apiKey = '';

  if (viewContext === 'tenant') {
    apiKey = await generateTenantSearchKey();
  } else {
    apiKey = await generateSiteSearchKey();
  }

  if (apiKey) {
    useUserStore.getState().setMeilisearchToken(apiKey);
    failedRequest.response.config.headers.Authorization = `Bearer ${apiKey}`;
  }

  return Promise.resolve();
};
