import createPoll from './create-poll.json';
import details from './details.json';
import editPoll from './edit-poll.json';
import polls from './polls.json';

export default {
  polls,
  createPoll,
  editPoll,
  details,
};
