import qs from "qs";

export const decodeAndParseQueryParams = (urlSearch: string) => {
  return qs.parse(urlSearch, {
    //Decoder is function to change number and boolean type that are string into Int and boolean.
    decoder(string, _decoder, charset) {
      const strWithoutPlus = string.replace(/\+/g, " ");

      if (charset === "iso-8859-1") {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, decodeURIComponent);
      }

      if (/^(\d+|\d*\.\d+)$/.test(string)) {
        return parseFloat(string);
      }

      const keywords = {
        ["true"]: true,
        ["false"]: false,
        ["null"]: null,
        ["undefined"]: undefined,
      };

      if (string in keywords) {
        return keywords[(string as "true" | "false" | "null", "undefined")];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  });
};
